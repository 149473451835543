import { GDocumentTaxonomies } from "../graphqlQueryPartials";

export const GInternalDocument = `
id
databaseId
title
slug
link
nodeType: __typename
contentTypeName
modified
documentThumbnail: featuredImage {
  image: node {
    mediaItemUrl
    altText
    title
  }
}
documentFields {
  abstract
  publicationYear
  documents {
    type
    documentLink
    documentLinkTitle
    documentLinkType
    documentFile {
      id
      databaseId
      title
      caption
      altText
      description
      link
      slug
      uri
      mediaItemUrl
      mediaType
      mimeType
      fileSize
    }
    documentLanguage {
      name
      languageISOFields {
        iso
      }
    }
  }
}
${GDocumentTaxonomies}
`;

export const GInternalDocumentListView = `
id
databaseId
title
slug
link
nodeType: __typename
contentTypeName
documentThumbnail: featuredImage {
  image: node {
    mediaItemUrl
    altText
    title
  }
}
documentFields {
  abstract
  publicationYear
}
documentKeywords {
  keywords: nodes {
    id
    count
    link
    name
    description
  }
}
`;

export const GInternalDocumentModalView = `
  id
  databaseId
  title
  nodeType: __typename
`;
