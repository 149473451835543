export class ValidationStore {
  /**
   * Validate the email string input parameter.
   * @param  {string} username [The email string you want the email to be set to.]
   * @return {boolean}     [Return True if the email matches the requirements, else False.]
   */
  validateUsername = (username: string) => {
    return /^\S*^[A-Za-z0-9]{3,16}$/.test(username);
  };

  /**
   * Validate the email string input parameter.
   * @param  {string} newName [The email string you want the email to be set to.]
   * @return {boolean}     [Return True if the email matches the requirements, else False.]
   */
  validateNewName = (newName: string) => {
    return /^[\w ]*[^\W_][\w ]*$/.test(newName) && newName.length >= 3 && newName.length <= 48;
  };

  /**
   * Validate the email string input parameter.
   * @param  {string} email [The email string you want the email to be set to.]
   * @return {boolean}     [Return True if the email matches the requirements, else False.]
   */
  validateEmail = (email: string) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
  };

  /**
   * Validate the password string input parameter.
   * The password needs at least one number, one lowercase, one uppercase letter and be at least six characters long.
   * @param  {string} password [The password string you want to validate]
   * @return {boolean}     [Return True if the password matches the requirements, else False.]
   */
  validatePassword = (password: string) => {
    return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(password);
  };

  /**
   * Return an username validation error message.
   * @param  {string} username [The username string you want to validate]
   * @return {string}  [The username error message]
   */
  getUsernameErrorMessage = (username: string) => {
    if (username?.length < 3) {
      return "Username needs to be at last 3 characters.";
    } else if (username?.length > 48) {
      return "Username needs to be at less than 48 characters.";
    } else if (/\s/.test(username)) {
      return "Username cannot contain spaces.";
    }
  };

  /**
   * Return an newName validation error message.
   * @param  {string} newName [The newName string you want to validate]
   * @return {string}  [The newName error message]
   */
  getNewNameErrorMessage = (newName: string) => {
    if (newName?.length < 3 && newName?.length !== 0) {
      return "Name needs to be at last 3 characters.";
    } else if (newName?.length > 16) {
      return "Name needs to be at less than 16 characters.";
    } else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(newName)) {
      return "Name cannot contain special characters.";
    }
  };

  /**
   * Return an email validation error message.
   * @return {string}  [The email error message]
   */
  getEmailErrorMessage = () => {
    return "The email is not valid.";
  };

  /**
   * Check the password input parameter and return an error message depending on which error is present.
   * The password needs at least one number, one lowercase, one uppercase letter and be at least six characters long.
   * @param  {string} password [The password string you want to validate]
   * @param  {string} passwordRepeat [passwordRepeat=""] - The repeated password to compare the first parameter to. This parameter is optional.
   * @return {string}     [The error message for the password/-s passed to the function.]
   */
  getPasswordErrorMessage = (password: string, passwordRepeat: string = "") => {
    if (password?.length < 6) {
      return "Password needs to be at least 6 characters long.";
    } else if (!/(?=.*[a-z])/.test(password)) {
      return "Password needs at least one lower case letter.";
    } else if (!/(?=.*[A-Z])/.test(password)) {
      return "Password needs at least one upper case letter.";
    } else if (!/(?=.*\d)/.test(password)) {
      return "Password needs at least one number.";
    } else if (password !== passwordRepeat) {
      return "The passwords does not match.";
    }
  };
}
