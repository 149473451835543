import React, { Component, CSSProperties } from "react";
import * as s from "./Overlay.module.scss";
import { inject, observer } from "mobx-react";
import { SectionsContainerStore } from "~stores/SectionsContainerStore";

export interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  sectionsContainerStore?: SectionsContainerStore;
}
@inject("sectionsContainerStore")
@observer
export class Overlay extends Component<OverlayProps> {
  render() {
    const { sectionsContainerStore, className, children, ...rest } = this.props;
    const { sectionsContainerYOffset } = sectionsContainerStore;

    const classes = [s.overlay];

    const overlayStyle: CSSProperties = {
      top: `${sectionsContainerYOffset}px`
    };

    return (
      <div className={classes.join(" ")} style={overlayStyle} {...rest}>
        {children}
      </div>
    );
  }
}
