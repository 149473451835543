import React, { Component, InputHTMLAttributes } from "react";

import * as s from "./Input.module.scss";
import { EyeSmall, HiddenSmall } from "~libs/assets/icons";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "email" | "password" | "textarea" | "number" | "date";
  label?: string;
  placeholder?: string;
  htmlFor?: string;
  value?: string;
  onChange?: (string) => void;
  onEnter?: () => void;
  onBlur?: () => void;
  showIcon?: boolean;
  error?: boolean;
  errorMessage?: string;
}

interface State {
  hasBeenClicked: boolean;
  passwordVisible: boolean;
}

export class Input extends Component<Props, State> {
  inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      hasBeenClicked: false,
      passwordVisible: false
    };
    this.inputRef = React.createRef();
  }

  onChange = e => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e.target.value);
    }
  };

  onKeyUp = e => {
    const { onEnter } = this.props;
    if (onEnter && e.keyCode === 13) {
      onEnter();
    }
  };

  onClickHandler = () => {
    this.setState({ hasBeenClicked: true });
  };

  onBlur = () => {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur();
    }
  };

  toggleShowPassword = () => {
    this.inputRef?.current?.focus();
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  renderTextAreaInput = classes => {
    const { htmlFor, placeholder, value, required } = this.props;

    return <textarea id={htmlFor} className={classes.join(" ")} placeholder={placeholder} value={value} onChange={this.onChange} onKeyUp={this.onKeyUp} onFocus={this.onClickHandler} onBlur={this.onBlur} autoComplete={"off"} required={required ?? false} />;
  };

  renderNormalInput = classes => {
    const { type, htmlFor, placeholder, value, required } = this.props;
    const { passwordVisible } = this.state;

    return <input ref={this.inputRef} id={htmlFor} type={!passwordVisible ? type : "text"} className={classes.join(" ")} placeholder={placeholder} value={value} onChange={this.onChange} onKeyUp={this.onKeyUp} onFocus={this.onClickHandler} onBlur={this.onBlur} autoComplete={"off"} required={required ?? false} />;
  };

  render() {
    const { type, label, htmlFor, placeholder, value, showIcon, error, errorMessage, className, ...rest } = this.props;
    const { hasBeenClicked, passwordVisible } = this.state;

    const inputContainerClasses = [s.inputContainer];
    const labelClasses = [s.label];
    const inputfieldClasses = [s.inputField];
    const errorMessageClasses = [s.errorMessage];

    if (className) inputContainerClasses.push(className);
    if (error) errorMessageClasses.push(s.visible);

    if (type === "date" || hasBeenClicked || !!value?.length) {
      labelClasses.push(s.hasBeenClicked);
      inputfieldClasses.push(s.hasBeenClicked);
    }

    if (type === "textarea") {
      labelClasses.push(s.textarea);
    }
    let icon;
    if (passwordVisible) {
      icon = <EyeSmall className={s.viewPasswordIcon} onClick={this.toggleShowPassword} />;
    } else {
      icon = <HiddenSmall className={s.viewPasswordIcon} onClick={this.toggleShowPassword} />;
    }

    return (
      <div className={inputContainerClasses.join(" ")} onClick={this.onClickHandler}>
        <label htmlFor={htmlFor} className={labelClasses.join(" ")}>
          {label}
        </label>
        {type === "textarea" ? this.renderTextAreaInput(inputfieldClasses) : this.renderNormalInput(inputfieldClasses)}
        {type === "password" && showIcon && icon}
        <label className={errorMessageClasses.join(" ")}>{errorMessage}</label>
      </div>
    );
  }
}
