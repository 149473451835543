import { makeAutoObservable, runInAction, toJS } from "mobx";

import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { ISingleWordpressDocument } from "~models/Document";
import { ICollectionSliderRow } from "~models/Section";

export class CollectionSliderStore {
  currentRow: ICollectionSliderRow;

  currentRowIdx: number = 2;

  page: number = 0;

  currentRowDocuments: ISingleWordpressDocument[] | [];

  sliderSearchIdx = 0;

  get numberOfDocuments() {
    return this.currentRowDocuments?.length || 0;
  }

  get visibleDocumentsCount() {
    return Math.min(this.currentRowDocuments?.length || 0, 8 + this.page * 4);
  }

  get documentsToRender() {
    return this.currentRowDocuments?.slice(0, this.visibleDocumentsCount);
  }

  get hasMore() {
    return this.visibleDocumentsCount < this.numberOfDocuments || (this.visibleDocumentsCount < 9 && this.numberOfDocuments > 4 && this.page === 0);
  }

  constructor(private rootStore: Store, private client: ApiClient) {
    makeAutoObservable(this);
  }

  initializeCollectionSlider = async (rows: ICollectionSliderRow[]) => {
    this.currentRowDocuments = [];
    const isDesktop = typeof window !== "undefined" && window.innerWidth > 600;
    const startIdx = rows.length >= 5 ? (isDesktop ? 2 : 1) : 0;
    if (rows[startIdx]) {
      await this.setCurrentRow(rows[startIdx], startIdx);
    }
  };

  setCurrentRow = (row: ICollectionSliderRow, idx: number) => {
    this.currentRow = row;
    this.currentRowIdx = idx;
    this.page = 0;
    this.getCurrentRowDocuments();
  };

  getCurrentRowDocuments = async () => {
    let documents = this.currentRow?.collection?.internalCollectionFields?.publicPosts || getRowDocuments(this.currentRow.topic) || this.currentRow?.keyword?.documents?.documents || [];
    runInAction(() => {
      this.currentRowDocuments = documents?.sort((a, b) => {
        if (!a?.documentFields?.publicationYear || !b?.documentFields?.publicationYear) return -1;
        return parseInt(b?.documentFields?.publicationYear, 10) - parseInt(a?.documentFields?.publicationYear, 10);
      });
    });
  };

  showMoreDocuments = () => {
    this.page++;
  };

  searchDocuments = async (modifier: object) => {
    const request = {
      page: 1,
      language: this.rootStore.languageStore?.currentLanguage?.slug || this.rootStore.languageStore.getUrlLanguageSlug() || "en",
      sort_by: "Latest",
      page_size: 8,
      no_taxonomies: true,
      ...modifier
    };
    const response = await this.client.getDocuments(JSON.stringify(request));
    return response?.data?.documents;
  };
}

function getRowDocuments(topic?: ICollectionSliderRow["topic"]) {
  if (!topic) return undefined;

  if (!!topic?.documents.documents.length) return topic?.documents.documents;

  return topic?.translations?.find(translation => translation.language.locale === "en_US")?.documents?.documents ?? undefined;
}
