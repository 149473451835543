import { makeAutoObservable } from "mobx";
import { IContext } from "~models/Context";

export class ContextStore {
  constructor(pageContext: IContext) {
    this.context = pageContext;
    makeAutoObservable(this);
  }

  context: IContext;

  setContext = (newContext: IContext) => {
    this.context = newContext;
  };
}
