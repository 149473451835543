import React, { CSSProperties } from "react";
import * as s from "./Paragraph.module.scss";

export interface ParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  big?: boolean;
  medium?: boolean;
  small?: boolean;
  wrap?: "pre-wrap" | "nowrap";
}

export const P = ({ big, medium, small, wrap, style, className, children, ...rest }: ParagraphProps) => {
  const mstyle: CSSProperties = {
    whiteSpace: wrap,
    ...(style || {})
  };

  const classes = [];

  if (medium && small) {
    throw new Error("Cannot set both medium and small");
  }

  if (big) {
    classes.push(s.big);
  }

  if (medium) {
    classes.push(s.medium);
  }

  if (small) {
    classes.push(s.small);
  }

  if (className) {
    classes.push(className);
  }

  return (
    <p className={classes.join(" ")} style={mstyle} {...rest}>
      {children}
    </p>
  );
};
