import React from "react";
import * as s from "./PseudoLink.module.scss";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  onEnter?: () => void;
}

export class PseudoLink extends React.Component<Props> {
  onClickHandler = e => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    }
  };

  onKeyUpHandler = e => {
    const { onClick, onEnter } = this.props;
    if (e.keyCode === 13) {
      if (onEnter) {
        onEnter();
      } else if (onClick) {
        onClick();
      }
    }
  };

  render() {
    const { className, children } = this.props;

    let pseudoLinkClasses = [s.pseudoLink];

    if (className) pseudoLinkClasses.push(className);

    return (
      <a onClick={this.onClickHandler} onKeyUp={this.onKeyUpHandler} className={pseudoLinkClasses.join(" ")} tabIndex={0}>
        {children}
      </a>
    );
  }
}
