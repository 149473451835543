import { tx } from "~libs/i18n";
import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";

const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface IEmailValidatorOptions extends ValidatorOptionProps {
  errorName: string;
}

export const EmailValidator: ValidatorFuncProps = (value: string, { errorName }: IEmailValidatorOptions) => {
  let valid = true;
  let error: string;

  if (!value) {
    valid = false;
    error = tx("validators.missing", {
      name: errorName
    });
  } else if (!regexp.test(value)) {
    valid = false;
    error = tx("validators.email");
  }

  return { valid, error };
};

export const STCDomainValidatorPattern = `^[a-zA-Z0-9._%+-]+@(?:savethechildren\.org|savethechildren\.org\.au|savethechildren\.be|savethechildren\.ca|redbarnet\.dk|pelastakaalapset\.fi|savethechildren\.fi|savethechildren\.de|barnaheill\.is|savethechildren\.in|stc\.or\.id|savethechildren\.nl|scnz\.org\.nz;|savethechildren\.org\.nz|reddbarna\.no|salvaticopiii\.ro|savethechildren\.org\.za|rb\.se|savethechildren\.ch|savethechildren\.org\.uk|savechildren\.org|sc\.or\.kr|savethechildren\.or\.kr|swace\.se)$`;
