import { tx } from "~libs/i18n";
import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";

export interface ITextValidatorOptions extends ValidatorOptionProps {
  errorMessage: string;
  min?: number;
  max?: number;
}

export const TextValidator: ValidatorFuncProps = (value: string, { errorMessage, min, max }: ITextValidatorOptions) => {
  const normStrValue = value || "";
  const nrChars = normStrValue.length;

  let valid = true;
  let error: string;

  if (nrChars == 0) {
    valid = false;
    //Todo: translation
    error = tx("validators.empty_field", {
      name: errorMessage
    });
  } else if (min && min > nrChars) {
    valid = false;
    //Todo: translation
    error = tx("validators.chars_too_few", {
      name: errorMessage
    });
  } else if (max && max < nrChars) {
    valid = false;
    //Todo: translation
    error = tx("validators.chars_too_many", {
      name: errorMessage
    });
  }

  return { valid, error };
};
