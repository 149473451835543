import i18next from "i18next";

import { makeAutoObservable } from "mobx";
import { persist } from "mobx-persist";
import { Store } from "./Store";
import { initTranslations } from "~libs/i18n";
import { disableBodyScrollAndTranslate, resetBodyScroll, isBrowser } from "~libs/utils";
import { ISingleWordpressLanguage } from "~models/Languages";

export class LanguageStore {
  rootStore: Store;

  constructor(rootStore: Store, languages: ISingleWordpressLanguage[], langCode: string) {
    this.rootStore = rootStore;
    this.initLanguageStore(languages, langCode);
    makeAutoObservable(this);
  }

  languageStoreHydrated: boolean;

  languageOverlayOpen: boolean = false;

  selectedLanguageType: "default" | "autodetected" | "selected" = "default";

  defaultLanguage: ISingleWordpressLanguage = {
    locale: "en_US",
    name: "English",
    slug: "en",
    code: "EN"
  };

  autoDetectedLanguage: ISingleWordpressLanguage;

  @persist("object")
  selectedLanguage: ISingleWordpressLanguage;

  @persist("object")
  currentLanguage: ISingleWordpressLanguage = this.defaultLanguage;

  @persist("list")
  availableLanguages: ISingleWordpressLanguage[] = [];

  @persist("list")
  allLanguages: ISingleWordpressLanguage[] = [];

  intitialLang: string;

  onMount = () => {
    if (this.allLanguages && this.selectedLanguage) this.setSelectedLanguage(this.selectedLanguage);
  };

  setHydrated = (hydrated: boolean) => {
    this.languageStoreHydrated = hydrated;
  };

  toggleLanguageOverlay = () => {
    this.languageOverlayOpen = !this.languageOverlayOpen;
    if (this.languageOverlayOpen) {
      const tempCurrentYPos = typeof window !== "undefined" && window.pageYOffset;
      disableBodyScrollAndTranslate(0, tempCurrentYPos, 0);
    } else {
      resetBodyScroll();
    }
  };

  initLanguageStore = async (languages: ISingleWordpressLanguage[], intitialLang: string) => {
    let lang = this.selectedLanguage?.slug;
    if (intitialLang) this.intitialLang = lang || intitialLang;
    let currentUrlLocale = this.intitialLang || this.getUrlLanguageSlug();
    this.allLanguages = languages?.filter(lang => lang.slug != "ar");
    if (!languages?.find(l => l.slug === currentUrlLocale)) {
      currentUrlLocale = "en";
    }
    this.availableLanguages = languages
      ?.filter(lang => lang.slug !== currentUrlLocale)
      ?.filter(lang => lang.slug != "ar")
      .filter(lang => currentUrlLocale || (!currentUrlLocale && lang.slug !== "en"));
    await initTranslations(currentUrlLocale);
    this.setAutoDetectedLanguage();
    this.sortAvailableLanguages();
    this.setCurrentLanguage();
    this.rootStore?.menuStore?.populateSelectedMenuVariables();
  };

  compareLangObjects = (first, second) => {
    if (first.name < second.name) {
      return -1;
    }
    if (first.name > second.name) {
      return 1;
    }
    return 0;
  };

  getUrlLanguageSlug = () => {
    let locale = this.defaultLanguage.slug;
    if (isBrowser()) {
      const match = window.location.pathname.match(/^\/[a-z]{2}\//);
      locale = match && match.length ? match[0].slice(1, 3) : "";
    } else {
      locale = this.intitialLang;
    }
    return locale;
  };

  sortAvailableLanguages = () => {
    this.availableLanguages = this?.availableLanguages?.slice().sort(this.compareLangObjects);
  };

  setAutoDetectedLanguage = () => {
    var browserLanguageLocale = isBrowser() && navigator.language.slice(0, 2);

    if (this?.allLanguages?.some(lang => lang.locale.includes(browserLanguageLocale))) {
      this.autoDetectedLanguage = this.allLanguages.find(lang => lang.locale.includes(browserLanguageLocale));
      this.selectedLanguageType = "autodetected";
    }
  };

  setCurrentLanguage = () => {
    const currLang = this.selectedLanguage || this.allLanguages?.find(lang => lang.slug === (this.getUrlLanguageSlug() || "en"));
    i18next.changeLanguage(currLang?.slug, () => {
      this.currentLanguage = currLang;
    });
  };

  setSelectedLanguage = async locale => {
    if (this.availableLanguages.some(lang => lang.locale === locale)) {
      this.selectedLanguage = this.availableLanguages.find(lang => lang.locale === locale);
      i18next.changeLanguage(this.selectedLanguage.slug);
      this.currentLanguage = this.selectedLanguage;
      this.availableLanguages = this.allLanguages.filter(lang => lang.locale !== locale);
      this.sortAvailableLanguages();
    }
  };

  navigateToLanguage = async () => {
    if (!this.selectedLanguage) return;
    let currentLanguageSlug = this.getUrlLanguageSlug();
    let targetLocale = "";
    let navigate = false;

    if (currentLanguageSlug !== this.selectedLanguage?.slug) {
      navigate = true;
      targetLocale = this.selectedLanguage?.slug !== "en" && this.selectedLanguage?.slug !== "nl" ? "/" + this.selectedLanguage?.slug : "";
      if (isBrowser() && navigate) {
        await this.setSelectedLanguage(targetLocale);
        const targetPath = window.location.pathname.substr(1, 2) !== currentLanguageSlug ? window.location.pathname : null;
        const currentURL = window.location.href;
        const newURL = `${window.location.origin}${targetLocale}${targetPath ? targetPath : ""}`;
        if (this.doesFileExist(newURL)) {
          window.location.href = newURL;
        } else {
          window.location.href = currentURL;
        }
      }
    }
  };

  doesFileExist = urlToFile => {
    var xhr = new XMLHttpRequest();
    xhr.open("HEAD", urlToFile, false);
    xhr.send();
    const statusType = xhr.status.toString().charAt(0);

    return statusType === "2" || statusType === "3";
  };

  getCurrentLanguageTranslated = () => {
    switch (this.currentLanguage?.slug) {
      case "es":
        return "Spanish";
      case "sv":
        return "Swedish";
      case "fr":
        return "French";
      case "nl":
        return "Dutch";
      default:
        return "English";
    }
  };
}
