import { makeAutoObservable, runInAction } from "mobx";
import { ApiClient } from "~libs/api";
import { removeAtIndex } from "~libs/utils";
import { ISubscriptionTaxonomyTypes } from "~models/Subscription";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";
import { Store } from "./Store";

export class TaxonomyStore {
  constructor(private rootStore: Store, private unauthenticatedClient: ApiClient) {
    makeAutoObservable(this);
  }

  currentFetches: ISubscriptionTaxonomyTypes[] = [];

  taxonomies: { [key in ISubscriptionTaxonomyTypes]: ISingleDocumentTaxonomy[] } = {
    documentCountries: [],
    documentTopics: [],
    documentKeywords: [],
    documentAuthors: [],
    documentContentTypes: [],
    documentPublishers: []
  };

  getTaxonomy = (taxonomy: ISubscriptionTaxonomyTypes) => {
    if (!this.taxonomies[taxonomy].length) {
      this.fetchTaxonomy(taxonomy);
    }

    return this.taxonomies[taxonomy];
  };

  fetchTaxonomy = async (taxonomy: ISubscriptionTaxonomyTypes) => {
    const result = await this.unauthenticatedClient.getTaxonomies([taxonomy], this.rootStore.languageStore?.currentLanguage?.slug);

    runInAction(() => {
      if (result.ok) {
        this.taxonomies[taxonomy] = [...(result?.data[taxonomy] ?? [])];

        removeAtIndex(this.currentFetches, this.currentFetches.indexOf(taxonomy));
      }
    });

    return this.taxonomies[taxonomy];
  };
}
