export type TypeRequestAnimationFrame = (FrameRequestCallback) => number;
export type TypeCancelAnimationFrame = (number) => void;

export const getRequestAnimationFrame: () => TypeRequestAnimationFrame = () => {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    (<any>window).mozRequestAnimationFrame ||
    (<any>window).oRequestAnimationFrame ||
    (<any>window).msRequestAnimationFrame ||
    function(callback) {
      return window.setTimeout(callback, 1000 / 60);
    }
  );
};

export const onAnimationFrame = (func: FrameRequestCallback, times?: number) => {
  if (times) {
    return new Promise(resolve => {
      runOnAnimationFrame(func, 0, times, resolve);
    });
  }
  return getRequestAnimationFrame()(func);
};

const runOnAnimationFrame = (
  func: FrameRequestCallback,
  currentTime: number,
  maxTimes: number,
  resolve: (any) => void
) => {
  getRequestAnimationFrame()(time => {
    func(time);
    if (currentTime < maxTimes - 1) {
      runOnAnimationFrame(func, currentTime + 1, maxTimes, resolve);
    } else {
      resolve(true);
    }
  });
};

export const getCancelAnimationFrame: () => TypeCancelAnimationFrame = () => {
  return window.cancelAnimationFrame || (<any>window).mozCancelAnimationFrame;
};

export const cancelAnimationFrame = (number: number) => {
  return getCancelAnimationFrame()(number);
};
