import i18next from "i18next";
import Backend from "i18next-http-backend";
import en from "./en";

export const initTranslations = lang =>
  i18next.use(Backend).init({
    lng: lang,
    fallbackLng: "en",
    whitelist: ["en", "es", "sv", "fr", "nl"],
    debug: false,
    cleanCode: true,
    resources: {
      en
    },
    interpolation: {
      format: (value, format, _) => {
        switch (format) {
          case "upper":
            return value.toUpperCase();
          case "lower":
            return value.toLowerCase();
          case "capital":
            return value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase();
        }

        return value;
      }
    },
    partialBundledLanguages: true,
    backend: {
      loadPath: "/translations/{{lng}}.json"
    }
  });
