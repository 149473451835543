import { makeAutoObservable } from "mobx";

import { Store } from "./Store";
import { ISingleWordpressMenu } from "~models/Menu";

export class MenuStore {
  rootStore: Store;

  allPrimaryHeaderMenus: ISingleWordpressMenu[] = [];

  primaryHeaderMenu: ISingleWordpressMenu;

  allSecondaryHeaderMenus: ISingleWordpressMenu[] = [];

  secondaryHeaderMenu: ISingleWordpressMenu;

  allFooterMenus: ISingleWordpressMenu[] = [];

  footerMenu: ISingleWordpressMenu;

  accountMenu: { staffOnly: boolean; label: string; path: string }[] = [
    {
      staffOnly: true,
      label: "header.discovery",
      path: "/account/discovery/"
    },
    {
      staffOnly: true,
      label: "header.evaluationHub",
      path: "/evaluation-hub/"
    },
    {
      staffOnly: false,
      label: "header.collections",
      path: "/account/user-profile/#my-collections"
    },
    {
      staffOnly: false,
      label: "profile.savedResources",
      path: "/account/user-profile/#my-library"
    },
    {
      staffOnly: false,
      label: "UploadDocumentsModal.uploadContent",
      path: "#upload_content"
    }
  ];

  constructor(rootStore: Store, menus: ISingleWordpressMenu[], langCode?: string) {
    this.rootStore = rootStore;
    this.initMenuStore(menus, langCode);
    makeAutoObservable(this);
  }

  /**
   * Init the store.
   * @param [All the wordpress menus found in the cms.]
   */
  initMenuStore = (menus: ISingleWordpressMenu[], langCode?: string) => {
    this.resetStoreVariables();
    this.allPrimaryHeaderMenus = menus?.filter(menu => menu.locations?.[0]?.toLowerCase().includes("primary"));

    this.allSecondaryHeaderMenus = menus?.filter(menu => menu.locations?.[0]?.toLowerCase().includes("secondary"));

    this.allFooterMenus = menus?.filter(menu => menu.locations?.[0]?.toLowerCase().includes("footer"));

    this.setDefaultMenus();
    this.populateSelectedMenuVariables(langCode);
  };

  /**
   * Reset the store variables to a default state.
   */
  resetStoreVariables = () => {
    this.allPrimaryHeaderMenus = [];
    this.primaryHeaderMenu = {} as ISingleWordpressMenu;
    this.allSecondaryHeaderMenus = [];
    this.secondaryHeaderMenu = {} as ISingleWordpressMenu;
    this.allFooterMenus = [];
    this.footerMenu = {} as ISingleWordpressMenu;
  };

  /**
   * Go through all the different types of menus. Primary, secondary & footer. Find the default menu
   * for each respective menu and set the appropriate variable.
   */
  setDefaultMenus = () => {
    this.primaryHeaderMenu = this.allPrimaryHeaderMenus?.find(menu => menu.locations?.[0] === "PRIMARY_HEADER_MENU");

    this.secondaryHeaderMenu = this.allSecondaryHeaderMenus?.find(menu => menu.locations?.[0] === "SECONDARY_HEADER_MENU");

    this.footerMenu = this.allFooterMenus?.find(menu => menu.locations?.[0] === "FOOTER_MENU");
  };

  /**
   * Go through all the different types of menus. Primary, secondary & footer. If the menu exists in the
   * current language then that menu is selected. Otherwise the english menu is selected by default.
   */
  populateSelectedMenuVariables = (langCode?: string) => {
    const { languageStore } = this.rootStore;
    const { currentLanguage } = languageStore;
    let languagePrefix = currentLanguage?.slug || langCode;
    if (this.allPrimaryHeaderMenus?.some(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix))) {
      this.primaryHeaderMenu = this.allPrimaryHeaderMenus?.find(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix));
    }

    if (this.allSecondaryHeaderMenus?.some(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix))) {
      this.secondaryHeaderMenu = this.allSecondaryHeaderMenus?.find(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix));
    }

    if (this.allFooterMenus?.some(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix))) {
      this.footerMenu = this.allFooterMenus?.find(menu => menu.locations?.[0]?.toLowerCase().includes(languagePrefix));
    }
  };
}
