import { makeAutoObservable, toJS, reaction } from "mobx";
import { persist } from "mobx-persist";
import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { tx } from "~libs/i18n";
import { addOrRemoveFromArray, addToArray, removeFromArray } from "~libs/utils";
import { ISingleWordpressDocument } from "~models/Document";
import { ISingleDocumentTaxonomy, ISingleWordpressTermNode } from "~models/Taxonomies";

export interface ITaxonomyBase {
  id: number;
  value: string;
  slug: string;
  selected: boolean;
}

export interface ITopicTaxonomy extends ITaxonomyBase {
  contributionPercentage?: number;
  numberOfDocuments?: number;
}

export interface IHierarchicalTaxonomy extends ITaxonomyBase {
  contributionPercentage: number;
  numberOfDocuments: number;
  parent: number;
  children?: number[];
}

export type ITaxonomy = ITaxonomyBase | IHierarchicalTaxonomy;

export class FilterStore {
  rootStore: Store;
  isTaxonomy: boolean | undefined;
  apiClient: ApiClient;

  constructor(rootStore: Store, client: ApiClient, initWithCountries: boolean) {
    this.rootStore = rootStore;
    this.apiClient = client;
    this.initWithCountries = initWithCountries;
    makeAutoObservable(this);
    reaction(
      () => [this.rootStore.languageStore.currentLanguage],
      () => {
        if (this.initWithCountries) this.initFilterStoreWithCountries();
      }
    );
  }

  selectedSortingKey: "Relevance" | "Alphabetical" | "Latest" | "StaffFirst" | "this_month" | "three_months" | "six_months" | "historical" = "Latest";

  sortResultsOptions: { value: string; key: string; selected: boolean }[] = [
    {
      value: tx("filter.relevance"),
      key: "Relevance",
      selected: this.selectedSortingKey === "Relevance"
    },
    {
      value: tx("filter.alphabetical"),
      key: "Alphabetical",
      selected: this.selectedSortingKey === "Alphabetical"
    },
    {
      value: tx("filter.latest"),
      key: "Latest",
      selected: this.selectedSortingKey === "Latest"
    },
    {
      value: tx("filter.staffFirst"),
      key: "StaffFirst",
      selected: this.selectedSortingKey === "StaffFirst"
    }
  ];

  get selectedSortingOption() {
    return this.sortResultsOptions.find(item => item.selected);
  }

  get resourceTypeHasInternalSupport() {
    return ["resource", "collection", null].includes(this.selectedResourceType);
  }

  prioritize: { value: string; selected: boolean; slug: string }[] = [
    { value: tx("document.peerReviewed"), selected: false, slug: "peer_reviewed" },
    { value: tx("document.recommended"), selected: false, slug: "recommended" }
  ];

  initWithCountries: boolean;

  initialized: boolean = false;

  publicationYears: { value: number }[] = [];

  // Replace hardcoded Structure for dynamic one.
  topics: IHierarchicalTaxonomy[] = [];

  suggestTopics: ITopicTaxonomy[] = [];

  // Replace hardcoded author values
  authors: IAuthorTaxonomy[] = [];

  publishers: IHierarchicalTaxonomy[] = [];

  contentTypes: ITaxonomyBase[] = [];

  countries: ITaxonomyBase[] = [];

  languages: ITaxonomyBase[] = [];

  mediaFormats: ITaxonomyBase[] = [];

  keywords: ITaxonomyBase[] = [];

  licenses: ITaxonomyBase[] = [];

  regions: ITaxonomyBase[] = [];

  anyFilterActive: boolean | undefined;

  presetTaxonomy: ISingleDocumentTaxonomy | ISingleDocumentTaxonomy[] | ISingleWordpressTermNode | ISingleWordpressTermNode[] | null | undefined;

  taxonomyMap: {} = {};

  taxonomies: ISingleDocumentTaxonomy[] = [];

  selectedTaxonomies: ISingleDocumentTaxonomy[] = [];

  selectedResourceType: "resource" | "collection" | "article" | "toolkit" | null = "resource";

  collectionId: string | null = null;

  @persist
  publicIndex: boolean = true;

  @persist
  privateIndex: boolean = false;

  setCollectionId = (id: string | null) => {
    this.collectionId = id;
  };

  get isCollectionSearch() {
    return this.collectionId !== null;
  }

  updateTaxonomies(topics, authors, publishers, contentTypes, countries, keywords, languages, regions) {
    this.topics = topics;
    this.authors = authors;
    this.publishers = publishers;
    this.contentTypes = contentTypes;
    this.countries = countries;
    this.keywords = keywords;
    this.languages = languages;
    this.regions = regions;
  }

  private taxonomyConstants = {
    document_keywords: "DocumentKeyword",
    document_topics: "DocumentTopic",
    document_countries: "DocumentCountry",
    document_languages: "DocumentLanguage",
    document_publishers: "DocumentPublisher",
    document_authors: "DocumentAuthor",
    document_content_types: "DocumentContentType",
    document_regions: "DocumentRegion"
  };

  initFilterStore = (taxonomies: ISingleDocumentTaxonomy[], documents?: ISingleWordpressDocument[], presetTaxonomy?: ISingleDocumentTaxonomy | ISingleWordpressTermNode | null, isTaxonomy?: boolean) => {
    this.clearFilter();
    this.taxonomies = taxonomies;
    this.taxonomyMap = (taxonomies && taxonomies.reduce((map, obj) => ((map[obj.databaseId] = obj), map), {})) || [];
    this.setFilter(taxonomies, documents?.length, presetTaxonomy, isTaxonomy);
    this.initialized = true;
  };

  initFilterStoreWithCountries = async () => {
    if (this.initialized) return;
    this.initialized = true;
    const countries = await this.apiClient.getTaxonomies(["documentCountries"], this.rootStore.languageStore?.currentLanguage?.slug);
    this.initFilterStore(
      countries?.data?.documentCountries?.map(term => ({
        ...term,
        id: term?.databaseId?.toString()
      })),
      null,
      null,
      false
    );
  };

  updateSelectedResourceType = (type: typeof this.selectedResourceType, runOnFilterUpdate = false) => {
    this.selectedResourceType = type;

    if (runOnFilterUpdate) {
      this.onFilterUpdate();
    }
  };

  setFilter = async (taxonomies?: ISingleDocumentTaxonomy[], documentCount?: number, presetTaxonomy?: ISingleDocumentTaxonomy | ISingleWordpressTermNode, isTaxonomy?: boolean) => {
    this.clearFilter(true);
    if (!this.selectedTaxonomies) this.selectedTaxonomies = [];
    this.isTaxonomy = isTaxonomy;
    const allTaxonomies = taxonomies || toJS(this.taxonomies) || [];
    const parentChildMap = {};

    if (presetTaxonomy) {
      this.presetTaxonomy = Array.isArray(presetTaxonomy) ? presetTaxonomy : [presetTaxonomy];
    }

    let filteredTaxonomies = allTaxonomies;
    let searchFilteredTaxonomies = toJS(this.rootStore.searchStore.taxonomies) || [];
    if (searchFilteredTaxonomies && searchFilteredTaxonomies.length) {
      searchFilteredTaxonomies = searchFilteredTaxonomies.concat(this.selectedTaxonomies.filter(s => !s.count && !this.selectedTaxonomies.find(st => st.id === s.id)));
      const taxMap = toJS(this.taxonomyMap);
      filteredTaxonomies = searchFilteredTaxonomies.map(tax => {
        const mergedTax = {
          ...taxMap[tax.id],
          ...tax
        };
        if (mergedTax.parent) parentChildMap[mergedTax.parent] = [...(parentChildMap[mergedTax.parent] || []), mergedTax.id];
        return mergedTax;
      });
    }
    let topics = [];
    let publishers = [];
    let authors = [];
    let contentTypes = [];
    let countries = [];
    let keywords = [];
    let languages = [];
    let regions = [];

    if ((Array.isArray(this.presetTaxonomy) && this.presetTaxonomy?.length) || !!this.presetTaxonomy) {
      const array = Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];
      filteredTaxonomies = [...filteredTaxonomies, ...array];
    }

    for (let i = 0; i < filteredTaxonomies.length; i++) {
      const type = this.taxonomyConstants[filteredTaxonomies[i]?.type] || filteredTaxonomies[i]?.type || filteredTaxonomies[i]?.nodeType;
      const selected = this.selectedTaxonomies.find(t => t.id == filteredTaxonomies[i].id);
      if (type === "DocumentTopic") {
        if (!selected) {
          topics = this.populateTopicsVariable(filteredTaxonomies[i], documentCount, topics, parentChildMap);
        } else {
          topics = this.addToArray(topics, {
            ...selected,
            contributionPercentage: await this.calculateContributionPercentage(this.termOccurrenceInResult(selected), documentCount)
          });
        }
      } else if (type === "DocumentAuthor") {
        if (!selected) {
          authors = this.populateAuthorsVariable(filteredTaxonomies[i], documentCount, authors);
        } else {
          authors = this.addToArray(authors, {
            ...selected,
            contributionPercentage: await this.calculateContributionPercentage(this.termOccurrenceInResult(selected), documentCount)
          });
        }
      } else if (type === "DocumentPublisher") {
        if (!selected) {
          publishers = this.populatePublishersVariable(filteredTaxonomies[i], publishers, parentChildMap);
        } else {
          publishers = this.addToArray(publishers, selected);
        }
      } else if (type === "DocumentContentType") {
        if (!selected) {
          this.populateShallowTaxonomyVariable(filteredTaxonomies[i], contentTypes);
        } else {
          contentTypes = this.addToArray(contentTypes, selected);
        }
      } else if (type === "DocumentCountry") {
        if (!selected) {
          this.populateShallowTaxonomyVariable(filteredTaxonomies[i], countries);
        } else {
          countries = this.addToArray(countries, selected);
        }
      } else if (type === "DocumentKeyword") {
        if (!selected) {
          this.populateShallowTaxonomyVariable(filteredTaxonomies[i], keywords);
        } else {
          keywords = this.addToArray(keywords, selected);
        }
      } else if (type === "DocumentLanguage") {
        if (!selected) {
          this.populateShallowTaxonomyVariable(filteredTaxonomies[i], languages);
        } else {
          languages = this.addToArray(languages, selected);
        }
      } else if (type === "DocumentRegion") {
        if (!selected) {
          this.populateShallowTaxonomyVariable(filteredTaxonomies[i], regions);
        } else {
          regions = this.addToArray(regions, selected);
        }
      }
    }
    this.updateTaxonomies(topics, authors, publishers, contentTypes, countries, keywords, languages, regions);
    this.sortHierarchialTaxonomies();
    this.sortTaxonomies();
    this.suggestTopics = topics?.length > 0 ? topics : this.suggestTopics;
  };

  sortHierarchialTaxonomies = () => {
    this.topics = this.topics.slice().sort((a, b) => {
      return b.contributionPercentage - a.contributionPercentage;
    });
    this.authors = this.authors.slice().sort((a, b) => {
      return b.contributionPercentage - a.contributionPercentage;
    });
  };

  sortTaxonomies = () => {
    this.publishers = this.publishers.slice().sort((a, b) => a?.value.localeCompare(b?.value));
    this.contentTypes = this.contentTypes.slice().sort((a, b) => a?.value.localeCompare(b?.value));
    this.countries = this.countries.slice().sort((a, b) => a?.value.localeCompare(b?.value));
    this.keywords = this.keywords.slice().sort((a, b) => a?.value.localeCompare(b?.value));
    this.languages = this.languages.slice().sort((a, b) => a?.value.localeCompare(b?.value));
    this.regions = this.regions.slice().sort((a, b) => a?.value.localeCompare(b?.value));
  };

  resetFilterAndSearch = async () => {
    this.clearFilter(false, true);
    this.rootStore.searchStore.getDocuments();
  };

  resetFilter = () => {
    this.countries = this.getUnselectedArray(this.countries);
    this.keywords = this.getUnselectedArray(this.keywords);
    this.contentTypes = this.getUnselectedArray(this.contentTypes);
    this.languages = this.getUnselectedArray(this.languages);
    this.publishers = this.getUnselectedArray(this.publishers);
    this.mediaFormats = this.getUnselectedArray(this.mediaFormats);
    this.authors = this.getUnselectedArray(this.authors);
    this.topics = this.getUnselectedArray(this.topics);
    this.regions = this.getUnselectedArray(this.regions);
    this.prioritize = this.getUnselectedArray(this.prioritize);
    this.publicationYears = [];
    this.presetTaxonomy = null;
    this.selectedTaxonomies = [];
    this.anyFilterActive = this.isAnyFilterActive();
  };

  clearFilter = (saveSelected: boolean = false, savePreselected: boolean = false) => {
    this.countries = this.getClearedTerms(this.countries, saveSelected, savePreselected);
    this.keywords = this.getClearedTerms(this.keywords, saveSelected, savePreselected);
    this.contentTypes = this.getClearedTerms(this.contentTypes, saveSelected, savePreselected);
    this.languages = this.getClearedTerms(this.languages, saveSelected, savePreselected);
    this.publishers = this.getClearedTerms(this.publishers, saveSelected, savePreselected);
    this.mediaFormats = this.getClearedTerms(this.mediaFormats, saveSelected, savePreselected);
    this.authors = this.getClearedTerms(this.authors, savePreselected, saveSelected);
    this.topics = this.getClearedTerms(this.topics, saveSelected, savePreselected);
    this.regions = this.getClearedTerms(this.regions, saveSelected, savePreselected);
    this.publicationYears = saveSelected ? this.publicationYears : [];
    this.selectedTaxonomies = saveSelected ? this.selectedTaxonomies : [];
    this.presetTaxonomy = saveSelected || savePreselected ? this.presetTaxonomy : null;
    this.anyFilterActive = this.isAnyFilterActive();
  };

  getClearedTerms = <T extends ITaxonomyBase | ITopicTaxonomy | IAuthorTaxonomy>(terms: T[], saveSelected: boolean, savePreselected: boolean) => {
    if (saveSelected) {
      return terms.filter(term => term.selected);
    }

    if (((Array.isArray(this.presetTaxonomy) && this.presetTaxonomy.length) || !!this.presetTaxonomy) && savePreselected) {
      const array = Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];

      return terms.filter(term => array.some(preSelected => preSelected.slug == term.slug));
    }

    return [];
  };

  getUnselectedArray = <T extends {}>(array: T[]): T[] => {
    return array.map(item => {
      return { ...item, selected: false };
    });
  };

  isAnyFilterActive = (): boolean => {
    let anyOptionSelected = [this.countries, this.keywords, this.contentTypes, this.languages, this.publishers, this.mediaFormats, this.authors, this.topics, this.regions, this.prioritize].some(options => {
      return options.some(option => {
        return option.selected;
      });
    });

    return anyOptionSelected || this.publicationYears.length > 0;
  };

  populateShallowTaxonomyVariable = (taxonomyInputObject, taxonomyVariable) => {
    if (taxonomyVariable.some(taxonomyValue => taxonomyValue.id === taxonomyInputObject.id)) {
      return;
    }

    const array = !this.presetTaxonomy ? [] : Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];

    taxonomyVariable.push({
      id: taxonomyInputObject.id,
      value: taxonomyInputObject.transName || taxonomyInputObject.name,
      slug: taxonomyInputObject.slug,
      numberOfDocuments: taxonomyInputObject.count,
      selected: array.some(term => term.slug === taxonomyInputObject.slug) && array.some(term => term.nodeType === this.taxonomyConstants[taxonomyInputObject?.type])
    });
  };

  termOccurrenceInResult = (term: ISingleDocumentTaxonomy) => this.rootStore?.searchStore?.documentResults?.filter(doc => doc[term?.type]?.find(t => t.slug === term.slug))?.length;

  populateTopicsVariable = (topic: ISingleDocumentTaxonomy, documentCount?: number, topics?: Array<any>, parentChildMap?: Object) => {
    if (topics && topics.some(t => t.id === topic.id)) {
      return topics;
    }
    let documents = documentCount ?? this.rootStore?.searchStore?.documentResults?.length ?? 1;
    const newTopic = {
      id: topic.id,
      value: topic.transName || topic.name,
      slug: topic.slug,
      contributionPercentage: this.calculateContributionPercentage(this.termOccurrenceInResult(topic), documents),
      numberOfDocuments: topic.count,
      children: parentChildMap[topic.id],
      selected: false,
      type: topic?.type,
      parent: topic.parent
    };
    if (this.presetTaxonomy) {
      const array = Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];
      newTopic.selected = array.some(term => term.slug == topic.slug) && array.some(term => term.nodeType == "DocumentTopic");
    }
    topics.push(newTopic);
    return topics;
  };

  populatePublishersVariable = (publisher: ISingleDocumentTaxonomy, publishers?: Array<any>, parentChildMap?: Object) => {
    if (publishers && publishers.some(t => t.id === publisher.id)) {
      return publishers;
    }
    const newPublisher = {
      id: publisher.id,
      value: publisher.transName || publisher.name,
      slug: publisher.slug,
      numberOfDocuments: publisher.count,
      children: parentChildMap[publisher.id],
      selected: false,
      type: publisher?.type,
      parent: publisher.parent
    };
    if (this.presetTaxonomy) {
      const array = Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];
      newPublisher.selected = array.some(taxonomy => taxonomy.slug === publisher.slug) && array.some(taxonomy => taxonomy.nodeType === "DocumentPublisher");
    }
    publishers.push(newPublisher);
    return publishers;
  };

  populateAuthorsVariable = (authorList: any, documentCount?: number, authors?: any[]) => {
    let documents = documentCount ?? this.rootStore?.searchStore?.documentResults?.length ?? 1;

    const array = Array.isArray(this.presetTaxonomy) ? this.presetTaxonomy : [this.presetTaxonomy];
    const authorArray = Array.isArray(authorList) ? authorList : [authorList];

    return [
      ...(authors ?? []),
      ...authorArray
        /**
         * Filter out already selected authors
         */
        .filter(a => !authors?.some(b => a.slug === b.slug))
        /**
         * Format author
         */
        .map(author => ({
          id: author.id,
          value: author.name,
          slug: author.slug,
          contributionPercentage: this.calculateContributionPercentage(this.termOccurrenceInResult(author), documents ?? 1),
          numberOfDocuments: author.count,
          selected: array?.some(tax => tax?.slug === author.slug)
        }))
    ];
  };

  calculateContributionPercentage = (occurrences: number, documentCount: number) => (documentCount > 0 ? Math.round((occurrences * 100) / documentCount) : 0);

  toggleOption = (allOptions, optionObject) => {
    const optionIndex = allOptions.findIndex(item => item.id === optionObject.id);
    if ("children" in optionObject && optionObject?.children?.length > 0) {
      optionObject.children.forEach(child => {
        const childIndex = allOptions.findIndex(item => item.id === child);
        const childOption = allOptions[childIndex] ?? this.selectedTaxonomies.find(item => item.id === child);
        let updatedSelection = this.selectedTaxonomies;
        if (childOption) {
          // Handle grandchildren
          let grandChildren = [];
          if (childOption.children) {
            grandChildren = childOption.children.reduce((acc, grandChild) => {
              const grandChildIndex = allOptions.findIndex(i => i.id === grandChild);
              const grandChildOption = allOptions[grandChildIndex] ?? this.selectedTaxonomies.find(item => item.id === grandChild);
              grandChildOption.selected = !grandChildOption.selected;
              acc.push(grandChildOption);
              return acc;
            }, []);
          }
          if (!optionObject.selected) {
            childOption.selected = true;
            updatedSelection = grandChildren.length ? [...this.selectedTaxonomies, ...grandChildren, childOption] : addToArray(this.selectedTaxonomies, childOption);
          } else {
            childOption.selected = false;
            if (grandChildren.length) {
              grandChildren.forEach(g => removeFromArray(this.selectedTaxonomies, g));
            }
            updatedSelection = removeFromArray(this.selectedTaxonomies, childOption);
          }
          this.selectedTaxonomies = updatedSelection;
          if (childIndex !== -1) {
            allOptions[childIndex] = childOption;
          }
        }
      });
    }
    optionObject.selected = !optionObject.selected;
    allOptions[optionIndex] = optionObject;
    addOrRemoveFromArray(this.selectedTaxonomies, optionObject);
    this.onFilterUpdate();
  };

  toggleYear = (rangeStart: number | null = null, rangeEnd: number | null = null) => {
    if (rangeStart === null) {
      this.publicationYears = [];
    } else {
      this.publicationYears = [];
      let rangeEndSafe = rangeEnd ?? new Date().getFullYear();

      if (Number.isInteger(rangeStart) && Number.isInteger(rangeEnd) && rangeStart === rangeEnd && rangeStart <= rangeEndSafe) {
        this.publicationYears = [{ value: rangeStart }, { value: rangeEnd }];
      } else {
        for (let i = rangeStart; i <= rangeEndSafe; i++) {
          this.publicationYears.push({ value: i });
        }
      }
    }

    this.onFilterUpdate();
  };

  onFilterUpdate = () => {
    this.anyFilterActive = this.isAnyFilterActive();
    this.rootStore.searchStore.setPage(1);
    this.rootStore.searchStore.getDocuments();
  };

  setSortOption = (selectedOption: string | { key: string }, doSearch?: boolean) => {
    const key = (typeof selectedOption === "string" ? selectedOption : selectedOption.key) as typeof this.selectedSortingKey;

    this.sortResultsOptions.forEach(option => {
      option.selected = option.key === key;
    });

    this.selectedSortingKey = key;
    this.onSortingUpdate(doSearch);
  };

  onSortingUpdate = (doSearch?: boolean) => {
    if (doSearch) {
      this.rootStore.searchStore.getDocuments(null, true);
    }
  };

  togglePublicIndex = () => {
    this.publicIndex = !this.publicIndex;
    this.onFilterUpdate();
  };

  togglePrivateIndex = () => {
    this.privateIndex = !this.privateIndex;
    this.onFilterUpdate();
  };

  addToArray = (arr, el) => {
    if (arr && arr.some(t => t.id === el.id)) {
      return arr;
    } else {
      arr.push(el);
    }
    return arr;
  };
}
