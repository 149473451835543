import i18next from "i18next";

/**
 * Translates text.
 *
 * @param key The i18n key.
 * @param vars Additional values sure to replace.
 */
export const tx = (key: string, vars?: string | object, fallback?: any) => {
  const result = (key ? i18next.t(key, vars) : null) as string;
  if (result === key && typeof fallback !== "undefined") {
    return fallback;
  }
  return result;
};

/** makes it possible to interpolate a string with components
 *
 * {tWithComponent('doNotHaveAccountYet', [<Link key='0' to='/signup'>{t('signUp')}</Link>])}
 * "doNotHaveAccountYet": "Do not have account yet? Click {0} to start!"
 * https://github.com/AlexanderZaytsev/react-native-i18n/issues/38
 * perhaps there is native support for this in i18next but couldn't find it
 */

export const txWithComponent = (i18nKey, components) => {
  let string = tx(i18nKey);
  let results = [];
  if (!string) return results;
  components.forEach((component, idx) => {
    let splitedStrings = string && string.split("{" + idx + "}");
    if (splitedStrings) {
      results.push(splitedStrings[0]);
      results.push(component);
      string = splitedStrings[1];
    }
  });
  results.push(string);
  return results;
};

export const getCurrentLanguage = () => i18next.language;

export const getLanguages = () => i18next.languages;
