import "~globalstyles/styles.scss";
import provideStores from "./src/provide-stores";
export const wrapPageElement = provideStores;
export const onInitialClientRender = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-20TB1EHRGQ");
};
