import { ValidatorProps } from "~libs/validators";

export interface InputProps {
  id?: string;
  value?: string;
  label?: string;
  type?: "text" | "email" | "password" | "textarea";
  error?: string;
  placeholder?: string;
  style?: any;
}

export interface ApiInputProps extends InputProps {
  param: string;
  optional?: boolean;
  validator?: ValidatorProps;
  toEmail?: string;
  fromUrl?: string;
}

export const validateItem = (item: ApiInputProps, setError: boolean) => {
  if (item.validator) {
    const validationResult = item.validator.func(item.value, item.validator.options);
    if (setError) {
      item.error = validationResult.error;
    }
    return validationResult.valid;
  }
  return true;
};

export const validateItems = (items: ApiInputProps[], setError: boolean) => {
  let valid = true;
  items.some(item => {
    if (!validateItem(item, setError)) {
      valid = false;
      if (!setError) {
        return true;
      }
    }
    return false;
  });
  return valid;
};

export const parseFields = (data, formData) => {
  formData = data.forEach(element => {
    formData.append(element.param, element.value);
  });

  return formData;
};
