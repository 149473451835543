import { ITaxonomyKey } from "./OpenSearch";
export interface IFormData<T> {
  value: T;
  method: (T) => boolean;
  valid: boolean;
}

export interface IFormSelect {
  id: number;
  title: string;
  selected: boolean;
}

export type IFieldSubset = "evaluations";

export type IFieldType = "text" | "email" | "select" | "date_picker" | "number";

export interface IAcfConditionalLogic {
  field: string;
  operator: string;
  value: string;
}
export interface IField {
  key: string;
  label: string;
  conditional_logic?: IAcfConditionalLogic[][];
  name: string;
  type: string;
  message: string;
  menu_order: string;
  default_value: string;
  placeholder: string;
  choices: IOption[];
  value: string;
  required: boolean;
}

export interface IFieldGroup {
  title: string;
  taxonomy: ITaxonomyKey;
  terms: string[];
  fields: IField[];
}

export interface IOption {
  value: string;
  selected: boolean;
  id?: number;
}

export const formDataToApiData = (data: { [key: string]: IFormData<any> }) =>
  Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key].value;
    return acc;
  }, {});
