import { inject, observer } from "mobx-react";
import React, { Component, InputHTMLAttributes } from "react";

import * as s from "./ModalToolbar.module.scss";

import { tx } from "~libs/i18n";
import { UIStore } from "~stores/UIStore";
import { IActionButton } from "../BottomBar";
import { P } from "../Paragraph";
import { CancelNormal, BackSmall } from "~libs/assets/icons";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  onClose: any;
  button?: IActionButton;
  uiStore?: UIStore;
}

@inject("uiStore")
@observer
export class ModalToolbar extends Component<Props> {
  render() {
    const { title, onClose, button, uiStore } = this.props;
    const { isDesktop } = uiStore;
    return isDesktop ? (
      <div className={s.modalCloseContainer} onClick={onClose}>
        <P className={s.modalCloseText}>{tx("misc.close")}</P>
        <CancelNormal className={s.modalCloseIcon} />
      </div>
    ) : (
      <div className={s.modalCloseContainer}>
        {button && (
          <P className={s.toolbarButton} onClick={() => button.onClick()}>
            {button.title}
          </P>
        )}
        <P className={s.modalCloseText}>{title}</P>
        <BackSmall onClick={onClose} className={s.modalCloseIcon} />
      </div>
    );
  }
}
