export type TScreenKeys = "xs" | "sm" | "md" | "lg" | "xl";

export const screenBreakpoints: { [key in TScreenKeys]: number } = {
  xs: 0,
  sm: 520,
  md: 720,
  lg: 992,
  xl: 1200
};

export const containerMaxWidths: { [key in TScreenKeys]: number } = {
  xs: 612,
  sm: 612,
  md: 804,
  lg: 996,
  xl: 1332
};

export const getBreakpointFromWidth = (screenwidth: number): TScreenKeys => {

  let breakpoint: TScreenKeys = "xs";
  for (const [key, value] of Object.entries(screenBreakpoints)) {
    if (screenwidth >= value) {
      breakpoint = key as TScreenKeys;
    } else {
      break;
    }
  }
  return breakpoint;
  
}