import { GAuthor } from "../graphqlQueryPartials";
import { GDocument } from "./document";

export const GArticleResourceDropdowns = `
  __typename
  ... on Article_Articlefields_ArticleBody_ResourceDropdowns {
    title
    description
    nodeType: __typename
    dropdowns {
      dropdown {
        dropdownTitle
        dropdownItems {
          type
          dropdownItem {
            title
            url
            target
          }
          linkTitle: title
          linkImage: image
          linkDescription: description
          removeNofollow
          dropdownDocument {
            ... on Document {
              ${GDocument}
            }
          }
        }
      }
    }
  }
`;

export const GArticleModalView = `
  id
  databaseId
  title
  nodeType: __typename
`;

export const GArticleListView = `
  id
  databaseId
  slug
  uri
  title
  nodeType: __typename
  articleFields {
    title
    text
    imageGroup {
      imageReference
      imageCaption
      image {
        mediaItemUrl
        altText
        title
      }
    }
  }
`;

export const GArticle = `
  id
  databaseId
  slug
  uri
  link
  title
  nodeType: __typename
  author {
    user: node {
      ${GAuthor}
    }
  }
  articleFields {
    title
    text
    imageGroup {
      imageReference
      imageCaption
      image {
        mediaItemUrl
        altText
        title
      }
    }
    articleBody {
      ${GArticleResourceDropdowns}
    }
  }
`;
