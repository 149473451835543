export const GDocumentTaxonomies = `
  documentTopics {
    topics: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentKeywords {
    keywords: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentCountries {
    countries: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentRegions {
    regions: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentPublishers {
    publishers: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentContentTypes {
    contentTypes: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentLanguages {
    languages: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentAuthors {
    authors: nodes {
      id
      count
      link
      name
      description
    }
  }
  documentLicenses {
    Licenses: nodes {
      id
      count
      link
      name
      description
    }
  }
`;

export const GAuthor = `
  id
  name
  email
  firstName
  lastName
  userProfileImageFields {
    profileImage {
      id
      link
      altText
      title
    }
  }
`;

export const GUser = `
  id
  databaseId
  email
  name
  firstName
  lastName
  profileImage: avatar {
    url
  }
`;

export const GInternalMediaItem = `
  id
  databaseId
  title
  caption
  altText
  description
  link
  slug
  uri
  mediaItemUrl
  mediaType
  mimeType
  fileSize
`;

export const GInternalPDF = `
    id
    databaseId
    title
    caption
    altText
    description
    link
    slug
    uri
    mediaItemUrl
    mediaType
    mimeType
    modified
    fileSize
    parentId
    parentDatabaseId
`;

export const GToolkit = `
  id
  nodeType: __typename
  databaseId
  slug
  title
  link
  toolkitThumbnail: featuredImage {
    image: node {
      mediaItemUrl
      altText
      title
    }
  }
  toolkitFields {
    title
    description
  }
`;
