import { makeAutoObservable } from "mobx";
import { TScreenKeys, screenBreakpoints } from "~libs/constants";
import { onAnimationFrame, delay } from "~libs/utils";

type IglobalMessageType = "positive" | "neutral" | "error";

export class UIStore {
  constructor() {
    makeAutoObservable(this);
  }

  breakpoint: TScreenKeys = "xl";

  windowWidth: number = 0;

  windowHeight: number = 0;

  windowPageYOffset: number = 0;

  windowPageXOffset: number = 0;

  isDesktop: boolean = true;

  globalMessage: string = null;

  globalMessageType: IglobalMessageType = null;

  onMount = () => {
    window.addEventListener("resize", this.onResize);
    this.onResize();

    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
  };

  onUnmount = () => {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  };

  isEqualOrGreatherThanBreakpoint = (bp: TScreenKeys) => {
    if (this.windowWidth === 0) return null;
    return this.windowWidth >= screenBreakpoints[bp];
  };

  toggleGlobalMessage: () => void = () => {
    this.globalMessage = null;
  };

  resizeAction = () => {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.breakpoint =
      (Object.keys(screenBreakpoints).find((key: TScreenKeys) => {
        return screenBreakpoints[key] >= this.windowWidth;
      }) as TScreenKeys) || "xl";

    this.isDesktop = this.isEqualOrGreatherThanBreakpoint("lg");
  };

  onResize = () => {
    onAnimationFrame(this.resizeAction);
  };

  onScroll = () => {
    onAnimationFrame(() => {
      this.windowPageYOffset = window.pageYOffset;
      this.windowPageXOffset = window.pageXOffset;
    });
  };

  showGlobalMessage = async (message: string, type: IglobalMessageType = "neutral", delayMS = 0) => {
    this.globalMessage = message;
    this.globalMessageType = type;
    if (delayMS) {
      await delay(delayMS);
      this.globalMessage = null;
    }
  };
}
