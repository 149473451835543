import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { MobXProviderContext } from "mobx-react";
import { Store } from "./stores/Store";

let store;

const App = ({ element }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWpMenu(sort: { locations: ASC }) {
            menus: nodes {
              locations
              menuItems {
                items: nodes {
                  id
                  databaseId
                  parentId
                  label
                  url
                  path
                }
              }
            }
          }
          allWpLanguages: wp {
            defaultLanguage {
              name
              locale
              slug
              code
            }
            languages {
              name
              locale
              slug
              code
            }
          }
        }
      `}
      render={data => {
        store =
          store ??
          new Store({
            menus: data.allWpMenu.menus,
            languages: data.allWpLanguages.languages.filter(lang => lang.slug !== "nl"),
            defaultLanguage: data.allWpLanguages.defaultLanguage,
            location: element.props.location,
            pageContext: element.props.pageContext
          });
        return (
          <StoreWrapper store={store}>
            <MobXProviderContext.Provider value={store}>{element}</MobXProviderContext.Provider>
          </StoreWrapper>
        );
      }}
    />
  );
};

const StoreWrapper = ({ store, children }) => {
  React.useEffect(() => {
    store.onMount();
    return () => store.onUnmount();
  }, [store]);

  return children;
};

export default App;
