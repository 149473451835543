export const clearHash = () => {
  if (typeof window === "undefined" || !window.location.hash) {
    return;
  }

  if ("pushState" in history) {
    history.pushState("", document.title, window.location.toString().split("#")[0]);
  } else {
    window.location.hash = "";
  }
};
