import { makeAutoObservable, runInAction } from "mobx";
import { Store } from "./Store";
import { GraphqlClient, ApiClient } from "~libs/api";
import { ISingleWordpressInternalCollection, IInternalCollection } from "~models/InternalCollection";
import { ISingleWordpressInternalDocument } from "~models/InternalDocument";
import { navigate } from "gatsby";
import { ISingleWordpressMediaItem } from "~models/MediaItem";

export type InternalContentTypeName = "internal_document" | "internal_collection";
export enum InternalIdType {
  ID,
  SLUG,
  DATABASE_ID,
  SOURCE_URL
}
interface IPostInterface<T = IStoredCollections | IStoredDocuments> {
  storedPosts: T;
  getLastModified: (id: string, token?: string) => Promise<Response>;
  fetchPost: (id: string, token?: string) => Promise<Response>;
  graphqlName: string;
}

interface IStoredCollections {
  [collectionId: string]: ISingleWordpressInternalCollection | null;
}

interface IStoredDocuments {
  [documentId: string]: ISingleWordpressInternalDocument | null;
}

interface IStoredPdfs {
  [mediaItemId: string]: ISingleWordpressMediaItem & { tracking: boolean };
}

/**
 * TODO: Check for staff user ın fetches etc
 */

export class InternalDataStore {
  rootStore: Store;

  requestStatus: "idle" | "pending" | "error" = "idle";

  addToInternalCollectionModalOpen: boolean = false;

  selectedInternalDocument: ISingleWordpressInternalDocument;

  internalPostInterfaces: {
    internal_collection: IPostInterface<IStoredCollections>;
    internal_document: IPostInterface<IStoredDocuments>;
    internal_pdf: IPostInterface<IStoredPdfs>;
  } = {
    internal_collection: {
      storedPosts: {},
      getLastModified: this.graphqlClient.getInternalCollectionModified,
      fetchPost: this.graphqlClient.getInternalCollection,
      graphqlName: "internalCollection"
    },
    internal_document: {
      storedPosts: {},
      getLastModified: this.graphqlClient.getInternalDocumentModified,
      fetchPost: this.graphqlClient.getInternalDocument,
      graphqlName: "internalDocument"
    },
    internal_pdf: {
      storedPosts: {},
      getLastModified: this.graphqlClient.getInternalMediaItemModified,
      fetchPost: this.graphqlClient.getInternalPDF,
      graphqlName: "mediaItem"
    }
  };

  internalDocuments: Record<string, ISingleWordpressInternalDocument | null> = this.internalPostInterfaces.internal_document.storedPosts;

  internalPdfs: Record<string, (ISingleWordpressMediaItem & { tracking: boolean }) | null> = this.internalPostInterfaces.internal_pdf.storedPosts;

  internalCollections: Record<string, ISingleWordpressInternalCollection | null> = this.internalPostInterfaces.internal_collection.storedPosts;

  createInternalCollectionModalOpen: boolean = false;

  shareCollectionModalOpen: boolean = false;

  emailAutocompleteResult: string[] = [];

  constructor(rootStore: Store, private graphqlClient: GraphqlClient, private authenticatedClient: ApiClient) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getInternalDocumentTracking = async (slug: string, parentDatabaseId: number) => {
    const entry = Object.entries(this.internalPostInterfaces.internal_pdf.storedPosts).find(([_, value]) => value.slug === slug);

    if (!entry?.[0]) return;

    const storedPost = this.internalPostInterfaces.internal_pdf.storedPosts[entry[0]];

    if (!storedPost) return;

    const response = await this.authenticatedClient.client.post("api/v1/usage_tracking/get_internal_document_tracking", JSON.stringify({ slug, parentDatabaseId }));

    if (response.ok) {
      storedPost.tracking = !!response.data;
    }
  };

  getInternalPost = async (postId: string, type: InternalContentTypeName, idType?: InternalIdType) => {
    const postInterface = this.internalPostInterfaces[type];
    if (!postInterface) return null;

    const { storedPosts } = postInterface;

    if (typeof this.internalCollections[postId] === "undefined" || !("modified" in this.internalCollections?.[postId])) {
      await this.fetchInternalPost(postId, postInterface, idType);
    } else {
      await this.updateCachedPost(postId, postInterface, idType);
    }

    return storedPosts[postId];
  };

  /**
   * TODO: Handle private collection field
   */
  fetchInternalPost = async (postId: string, postInterface: IPostInterface, idType?: InternalIdType) => {
    if (this.requestStatus === "pending") {
      return;
    }
    this.requestStatus = "pending";

    const response = await postInterface.fetchPost(postId, idType);
    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            this.requestStatus = "error";
          } else {
            if (!!data[postInterface.graphqlName]) {
              postInterface.storedPosts[postId] = data[postInterface.graphqlName];
            } else {
              postInterface.storedPosts[postId] = null;
            }
            this.requestStatus = "idle";
          }
        });
      } else {
        this.requestStatus = "error";
      }
    });
  };

  /**
   * TODO: Check if collection has been made private
   */
  updateCachedPost = async (postId: string, postInterface: IPostInterface, idType?: InternalIdType) => {
    if (this.requestStatus === "pending") {
      return;
    }
    this.requestStatus = "pending";

    const response = await postInterface?.getLastModified(postId);

    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            this.requestStatus = "error";
          } else {
            this.requestStatus = "idle";
            const modified = data[postInterface.graphqlName]?.modified;

            if (modified !== postInterface.storedPosts[postId]?.modified) {
              this.fetchInternalPost(postId, postInterface, idType);
            }
          }
        });
      } else {
        this.requestStatus = "error";
      }
    });
  };

  fetchCurrentUserInternalCollections = async () => {
    if (this.requestStatus === "pending") {
      return;
    }
    this.requestStatus = "pending";

    const response = await this.graphqlClient.getCurrentUserInternalCollections();
    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        this.requestStatus = "error";
      } else {
        this.requestStatus = "idle";
        const objectMap = data?.viewer?.internalCollections?.nodes?.reduce((acc, currentCollection) => {
          acc[currentCollection.id] = currentCollection;
          return acc;
        }, {});
        this.internalCollections = objectMap;
        return data?.viewer?.internalCollections?.nodes;
      }
    } else {
      this.requestStatus = "error";
    }
  };

  updateInternalCollection = async (collectionId: number, data: { title: string; description: string; private: boolean }) => {
    if (this.requestStatus === "pending") {
      return;
    }
    this.requestStatus = "pending";

    const response = await this.authenticatedClient.updateInternalCollection(collectionId, data);

    runInAction(async () => {
      if (response.ok) {
        const { data } = response;

        /**
         * TODO: Fetch with graphql if update is successful?
         */
        this.internalPostInterfaces.internal_collection.storedPosts[data?.id] = data;

        this.requestStatus = "idle";
      } else {
        this.requestStatus = "error";
      }
    });
  };

  deleteInternalCollection = async (collectionDatabaseId: number, collectionId: string) => {
    if (this.requestStatus === "pending" || !collectionId || !collectionDatabaseId) {
      return;
    }

    this.requestStatus = "pending";

    const response = await this.authenticatedClient.deleteInternalCollection(collectionDatabaseId);

    return runInAction(async () => {
      if (response.ok) {
        delete this.internalCollections[collectionId];

        this.requestStatus = "idle";
        return true;
      } else {
        this.requestStatus = "error";
        return false;
      }
    });
  };

  grantCollectionAuthor = async (collectionId: number, userId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.internalCollectionAuthor(collectionId, userId);

    return this.requestResponseHelper(result);
  };

  addPendingCollaborators = async (collectionId: number, userIds: number[]) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.addPendingCollaborators(collectionId, userIds);
    return this.requestResponseHelper(result);
  };

  removeCollectionCollaborator = async (collectionId: number, userId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.removeCollectionCollaborator(collectionId, userId);

    runInAction(async () => {
      if (result.ok) {
        if (result.status === 204) {
          const key = Object.values(this.internalCollections).find(collection => collection.databaseId === collectionId).id;
          delete this.internalCollections[key];
          navigate("/account/user-profile/#my-collections");
        } else {
          this.internalPostInterfaces.internal_collection.storedPosts[result.data?.id] = result.data;
        }
        this.requestStatus = "idle";
      } else {
        this.requestStatus = "error";
      }
    });
    return result.data;
  };

  removePendingCollaborator = async (collectionId: number, userId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.removeCollectionPendingCollaborator(collectionId, userId);

    return this.requestResponseHelper(result);
  };

  createInternalCollection = async (data: IInternalCollection) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.createInternalCollection(data);
    return this.requestResponseHelper(result);
  };

  addInternalCollectionPost = async (collectionId: number, postId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";
    const result = await this.authenticatedClient.addInternalCollectionPost(collectionId, postId);
    return this.requestResponseHelper(result);
  };

  removeInternalCollectionPost = async (collectionId: number, postId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.removeInternalCollectionPost(collectionId, postId);
    return this.requestResponseHelper(result);
  };

  requestResponseHelper = result => {
    runInAction(async () => {
      if (result.ok) {
        this.internalPostInterfaces.internal_collection.storedPosts[result.data?.id] = result.data;
        this.internalCollections[result.data?.id] = result.data;
        this.requestStatus = "idle";
      } else {
        this.requestStatus = "error";
      }
    });
    return result.data;
  };

  toggleAddToInternalCollectionModal = (internalDocument?: ISingleWordpressInternalDocument) => {
    if (internalDocument) {
      this.selectedInternalDocument = internalDocument;
      this.addToInternalCollectionModalOpen = true;
    } else {
      this.addToInternalCollectionModalOpen = false;
    }
  };

  documentInInternalCollection = (documentId: number) => {
    if (!documentId) return false;
    const postIds = Object.values(this.internalCollections)
      .map(col => col?.internalCollectionFields?.internalDocuments?.map(post => post.databaseId))
      .flat();
    if (postIds.some(id => id === documentId)) {
      return true;
    }
    return false;
  };

  toggleCreateCollectionModal = () => (this.createInternalCollectionModalOpen = !this.createInternalCollectionModalOpen);

  toggleShareCollectionModal = () => (this.shareCollectionModalOpen = !this.shareCollectionModalOpen);

  autocompleteUsers = async (name: string) => {
    if (this.requestStatus === "pending") return;
    if (!name) {
      return runInAction(async () => {
        this.emailAutocompleteResult = [];
      });
    }
    this.requestStatus = "pending";
    const response = await this.authenticatedClient.autocompleteUsers(name);
    return runInAction(async () => {
      if (response.ok) {
        this.requestStatus = "idle";
        this.emailAutocompleteResult = response.data;
      }
    });
  };

  requestInternalCollectionPromotion = async (collectionId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.requestInternalCollectionPromotion(collectionId);

    return this.requestResponseHelper(result);
  };

  revokeInternalCollectionPromotionRequest = async (collectionId: number) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.revokeInternalCollectionPromotionRequest(collectionId);

    return this.requestResponseHelper(result);
  };

  sendCollectionInvites = async (collectionId: number, emails: string[]) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.sendCollectionInvites(collectionId, { emails });

    return this.requestResponseHelper(result);
  };

  removePendingInvite = async (collectionId: number, email: string) => {
    if (this.requestStatus === "pending") return;
    this.requestStatus = "pending";

    const result = await this.authenticatedClient.removeCollectionInvite(collectionId, email);

    return this.requestResponseHelper(result);
  };
}
