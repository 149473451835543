import { makeAutoObservable } from "mobx";
import Cookies from "js-cookie";

export class CookiesStore {
  public isOpen: boolean;

  constructor() {
    this.showCookieModal();
    makeAutoObservable(this);
  }

  showCookieModal = () => {
    if (Cookies.get("cookiesAccepted") !== "true") {
      this.isOpen = true;
    }
  };

  onCloseCookies = () => {
    this.isOpen = false;
  };

  onAcceptCookies = () => {
    this.onCloseCookies();
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    this.isOpen = false;
  };
}
