import { makeAutoObservable, runInAction } from "mobx";

import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { tx } from "~libs/i18n";
import { ISubscriptionTypes, ISubscriptionTaxonomy, defaultSubscriptionTaxonomies, ISubscriptionTaxonomyTypes, documentTaxonomyToTaxonomySelection, collectionToTaxonomySelection } from "~models/Subscription";
import { ChoiceButtonOption } from "~views/shared/components/ThreeChoiceButton";
import { ISubscription } from "./SubscriptionStore";
export class NewsletterSignupStore {
  constructor(private rootStore: Store, private unauthenticatedClient: ApiClient, private authenticatedClient: ApiClient) {
    makeAutoObservable(this);
  }

  overlayOpen: boolean = false;

  requestStatus: "idle" | "pending" | "complete" = "idle";

  allTaxonomies: {
    [key in ISubscriptionTypes]: ISubscriptionTaxonomy;
  } = defaultSubscriptionTaxonomies;

  FrequencyOptions: ChoiceButtonOption[] = [
    {
      label: tx("newsletter.frequency.daily"),
      value: "daily",
      selected: false
    },
    {
      label: tx("newsletter.frequency.weekly"),
      value: "weekly",
      selected: false
    },
    {
      label: tx("newsletter.frequency.monthly"),
      value: "monthly",
      selected: false
    }
  ];

  targetEmail: string = "";

  tacAccepted: boolean = false;

  emailInvalid: boolean = false;

  emailErrorMessage: string = "";

  get taxonomySelected() {
    return !!Object.values(this.allTaxonomies).reduce((acc, curr) => {
      return acc.concat(curr.items?.filter(tax => tax.selected));
    }, []).length;
  }

  onSubmit = async (subscription: ISubscription) => {
    if (this.requestStatus == "pending") {
      return;
    }
    this.requestStatus = "pending";
    const client = this.rootStore.authenticationStore.userHasValidToken ? this.authenticatedClient : this.unauthenticatedClient;
    const terms = Object.values(this.allTaxonomies)
      .reduce((acc, curr) => {
        return acc.concat(curr.items?.filter(tax => tax.selected));
      }, [])
      .map(item => ({
        nodeType: item.nodeType,
        id: item.id,
        name: item.value
      }));

    const result = await client.createNewsletterSubscription({
      ...subscription,
      terms
    });
    if (result && result.ok) {
      this.requestStatus = "complete";
      Object.values(this.allTaxonomies).forEach(tax => tax.items.forEach(item => (item.selected = false)));
      this.tacAccepted = false;
    } else {
      this.requestStatus = "idle";
    }
    setTimeout(() => {
      this.toggleOverlay();
      this.requestStatus = "idle";
    }, 1000);
  };

  toggleOverlay = () => {
    this.overlayOpen = !this.overlayOpen;
    if (this.overlayOpen) {
      this.rootStore.sectionsContainerStore.setScrollPos();
    } else {
      this.rootStore.sectionsContainerStore.resetScrollPos();
    }
  };

  setTargetEmail = e => {
    this.targetEmail = e;
  };

  toggleTAC = () => (this.tacAccepted = !this.tacAccepted);

  validateEmail = (email: string) => {
    this.emailInvalid = !this.rootStore.validationStore.validateEmail(email);

    if (this.emailInvalid) {
      this.emailErrorMessage = this.rootStore.validationStore.getEmailErrorMessage();
    }
  };

  validateTerms = () => {
    return !!this.tacAccepted;
  };

  loadTaxonomies = async (...taxs: ISubscriptionTaxonomy[]) => {
    const emptyTaxes = taxs?.filter(t => !t.fetched).map(t => t?.type);
    if (!emptyTaxes.length) {
      return;
    }

    const alreadyFetchedTaxonomies = taxs.filter(tax => this.allTaxonomies[tax.type].fetched);
    const toBeFetchedTaxonomies = taxs.filter(tax => !this.allTaxonomies[tax.type].fetched);

    alreadyFetchedTaxonomies.forEach(tax => {
      tax.items = [...this.allTaxonomies[tax.type].items]?.map(i => ({ ...i, selected: tax.items.some(tax => i.id === tax.id) }));
      tax.fetched = true;
    });

    /**
     * If we don't need to fetch anything, we return
     */
    if (toBeFetchedTaxonomies.length === 0) {
      return;
    }

    const taxes = emptyTaxes?.filter(tax => tax !== "collections") as ISubscriptionTaxonomyTypes[];
    const collection = emptyTaxes?.filter(tax => tax === "collections");

    if (taxes.length) {
      const result = await this.unauthenticatedClient.getTaxonomies(taxes, this.rootStore.contextStore?.context?.lang);
      runInAction(() => {
        if (result.data) {
          Object.keys(result.data).forEach(key => {
            if (result?.data[key] && Array.isArray(result?.data[key]) && this.allTaxonomies[key]) {
              const fetchedTaxonomy = taxs.find(tax => tax.type === key);
              if (!!fetchedTaxonomy) {
                fetchedTaxonomy.items = result?.data[key]?.map(documentTaxonomyToTaxonomySelection).map(i => ({ ...i, selected: fetchedTaxonomy.items.some(tax => i.id === tax.id) }));
                fetchedTaxonomy.fetched = true;
              }
              this.allTaxonomies[key].items = result?.data[key]?.map(documentTaxonomyToTaxonomySelection);
              this.allTaxonomies[key].fetched = true;
            }
          });
        }
      });
    }
    if (collection) {
      const result = await this.unauthenticatedClient.getCollections(this.rootStore.contextStore?.context?.lang);
      runInAction(() => {
        this.allTaxonomies.collections.items = result.data?.collections?.map(collectionToTaxonomySelection);
      });
    }
  };

  singleCollectionSubscription = (id: number) => {
    this.allTaxonomies.collections.items.forEach(item => {
      if (item.id === id) {
        item.selected = true;
      } else {
        if (item.selected) {
          item.selected = false;
        }
      }
    });
  };
}
