export const rewriteToInternal = url => {
  const regex = "^(.*:)//([A-Za-z0-9-.]+)(:[0-9]+)?(.*)$"; // eslint-disable-line
  if (url) {
    return `${process.env.GATSBY_WP_BASE_URL}${url.match(regex)[4]}`;
  }
  return "";
};

export const buildCanonicalUrl = (origin: string, url: string) => {
  if (origin && url) {
    return origin + url;
  }
};

export const isBrowser = () => typeof window !== "undefined";

export const pushToArray = (arr, obj) => {
  const index = arr.findIndex(e => e.id === obj.id);

  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = { ...arr[index], ...obj };
  }
};

export const removeDuplicates = a => {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = a[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
};

export const removeFromArray = (arr, item) => {
  const index = arr.findIndex(elem => item.id === elem.id);
  if (index === -1) return arr;

  const arrCopy = [].concat(arr);
  arrCopy.splice(index, 1);
  return arrCopy;
};

export const addToArray = (arr, item) => {
  const index = arr.findIndex(e => e.id === item.id);
  if (index === -1) return arr.concat(item);
  return arr;
};

export const addOrRemoveFromArray = <T extends { id: string }>(arr: T[], item: T) => {
  const index = arr?.findIndex(elem => item.id === elem.id);
  if (index === -1) arr.push(item);
  else arr.splice(index, 1);
};

export const getCookieValue = (name, cookie) =>
  cookie?.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
