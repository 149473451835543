import React from "react";
import { ModalToolbar } from "../ModalToolbar";
import { Overlay } from "../Overlay";
import * as styles from "./Modal.module.scss";

import { observer, inject } from "mobx-react";
import { useOutsideAlerter } from "~libs/hooks/useOutsideAlerter";
import { UIStore } from "~stores/UIStore";

export interface ModalProps {
  padding?: boolean;
  open: boolean;
  onClose: () => void;
  uiStore?: UIStore;

  title?: string;
  className?: string;
  children: React.ReactNode;
  width?: number;
  clickOutsideCloses?: boolean;
}
export const Modal = inject("uiStore")(
  observer(({ uiStore, padding, open, onClose, title, className, children, width, clickOutsideCloses = true }: ModalProps) => {
    const { toggleGlobalMessage } = uiStore;

    const containerRef = React.useRef(null);
    if (clickOutsideCloses) {
      const closeAll = () => {
        onClose();
        toggleGlobalMessage();
      };

      useOutsideAlerter(containerRef, closeAll);
    }

    if (!open) {
      return null;
    }

    const wrapperClasses = [styles.wrapper];
    if (padding) {
      wrapperClasses.push(styles.padding);
    }

    if (className) {
      wrapperClasses.push(className);
    }
    return (
      <Overlay>
        <div className={wrapperClasses.join(" ")} ref={containerRef} style={{ width: `${width || 564}px` }}>
          <ModalToolbar title={title} onClose={onClose} />
          {children}
        </div>
      </Overlay>
    );
  })
);
