import { makeAutoObservable, reaction, runInAction } from "mobx";

import { Store } from "./Store";
import { GraphqlClient, ApiClient } from "~libs/api";
import { tx } from "~libs/i18n";
import { removeAtIndex } from "~libs/utils";
import { ISubscriptionTaxonomyTypes } from "~models/Subscription";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";

type TaxonomyPreferences = Extract<ISubscriptionTaxonomyTypes, "documentCountries" | "documentTopics">;
interface Preference {
  taxonomy: ISubscriptionTaxonomyTypes;
  selectedItems: ISingleDocumentTaxonomy[];
  description: string;
}

export class PreferenceStore {
  constructor(private rootStore: Store, private graphqlClient: GraphqlClient, private unauthenticatedClient: ApiClient, private authenticatedClient: ApiClient) {
    makeAutoObservable(this);

    reaction(
      () => this.rootStore.userStore.currentUser,
      () => this.fetchUserPreferences()
    );

    reaction(
      () => this.preferencesFetched,
      () => {
        if (!this.preferencesFetched) {
          this.fetchUserPreferences();
        }
      }
    );
  }

  taxonomies: { [key in TaxonomyPreferences]: Preference } = {
    documentCountries: {
      taxonomy: "documentCountries",
      selectedItems: [],
      description: tx("staff.countriesDescription")
    },
    documentTopics: {
      taxonomy: "documentTopics",
      selectedItems: [],
      description: tx("staff.topicsDescription")
    }
  };

  preferencesFetched: boolean = false;

  currentIndex: number = -1;

  modalOpen: boolean = false;

  initPreferenceStore = () => {
    const taxonomies = Object.values(this.taxonomies);
    for (let i = 0; i < taxonomies.length; i++) {
      if (!taxonomies[i]?.selectedItems.length) {
        this.setCurrentIndex(i);
        this.toggleModalOpen();
        return;
      }
    }
  };

  fetchUserPreferences = async () => {
    if (!this.rootStore.userStore.isStaffUser) return;

    const response = await this.graphqlClient.getUserPreferences();

    if (response.ok) {
      const { data, errors } = await response.json();

      if (data.viewer) {
        let hasEmptyPreference = false;
        for (const [key, value] of Object.entries(data.viewer.userPreferences)) {
          if (value && Array.isArray(value)) {
            this.taxonomies[key].selectedItems = [...value].filter(val => !!val);
          } else {
            hasEmptyPreference = true;
          }
        }

        if (errors) {
          console.error(errors);
        } else {
          /**
           * TODO: Any additional checks that should be done? For example, if staff user is on a gated page?
           */
          if (hasEmptyPreference) {
            this.initPreferenceStore();
          } else {
            this.setCurrentIndex(0);
          }
        }
      }
    } else {
      console.error(response);
    }
    this.preferencesFetched = true;
  };

  updateUserPreferences = async () => {
    const data: any = {};

    /**
     * TODO: Add additional checks before sending request?
     */

    for (const [key, value] of Object.entries(this.taxonomies)) {
      data[key] = value.selectedItems.map(item => item.databaseId);
    }

    const response = await this.authenticatedClient.updateUserPreferences(data);

    if (response.ok) {
      this.toggleModalOpen();
      this.preferencesFetched = false;
    } else {
      console.error(response);
    }
  };

  get currentTaxonomy(): Preference {
    return this.taxonomies[Object.keys(this.taxonomies)?.[this.currentIndex]];
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
  }

  incrementIndex = () => {
    if (this.currentIndex + 1 < Object.keys(this.taxonomies)?.length) {
      this.currentIndex++;
    }
  };

  decrementIndex = () => {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  };

  toggleModalOpen = () => {
    this.modalOpen = !this.modalOpen;
  };

  getTaxonomy = async (taxonomy: Preference) => {
    const result = await this.unauthenticatedClient.getTaxonomies([taxonomy.taxonomy], this.rootStore.languageStore?.currentLanguage?.slug);

    runInAction(() => {
      if (result.ok) {
        this.taxonomies[taxonomy.taxonomy].allItems = [...(result?.data[taxonomy.taxonomy] ?? [])];
      }
    });
  };

  toggleSelectedItem = (preference: TaxonomyPreferences, item: ISingleDocumentTaxonomy) => {
    if (this.isItemSelected(preference, item)) {
      const index = this.taxonomies?.[preference]?.selectedItems?.findIndex(selectedItem => selectedItem.databaseId === item.databaseId);
      this.taxonomies[preference].selectedItems = removeAtIndex(this.taxonomies?.[preference].selectedItems, index);
    } else {
      this.taxonomies?.[preference].selectedItems.push(item);
    }
  };

  toggleAll = (preference: TaxonomyPreferences, allTerms: ISingleDocumentTaxonomy[]) => {
    this.taxonomies[preference].selectedItems = this.taxonomies?.[preference].selectedItems?.length === allTerms?.length ? [] : [...allTerms];
  };

  isItemSelected = (preference: TaxonomyPreferences, item: ISingleDocumentTaxonomy) => {
    return this.taxonomies?.[preference]?.selectedItems?.some(selectedItem => selectedItem.slug === item.slug);
  };
}
