import React, { Component } from "react";
import * as s from "./Button.module.scss";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size: "xsmall" | "small" | "medium" | "large" | "play";
  color: "red" | "white" | "gray" | "plain";
  disabled?: boolean;
}

export class Button extends Component<ButtonProps> {
  render() {
    const { size, color, disabled, onClick, className, ...rest } = this.props;

    const buttonClasses = [s.button];

    switch (size) {
      case "large":
        buttonClasses.push(s.large);
        break;
      case "medium":
        buttonClasses.push(s.medium);
        break;
      case "small":
        buttonClasses.push(s.small);
        break;
      case "xsmall":
        buttonClasses.push(s.xsmall);
        break;
      case "play":
        buttonClasses.push(s.play);
        break;
      default:
        buttonClasses.push(s.large);
        break;
    }

    switch (color) {
      case "red":
        buttonClasses.push(s.red);
        break;
      case "white":
        buttonClasses.push(s.white);
        break;
      case "gray":
        buttonClasses.push(s.gray);
        break;
      case "plain":
        buttonClasses.push(s.plain);
        break;
    }

    if (disabled) {
      buttonClasses.push(s.disabled);
    }

    if (className) {
      buttonClasses.push(className);
    }

    return <button disabled={disabled} onClick={disabled ? undefined : onClick} className={buttonClasses.join(" ")} {...rest} />;
  }
}
