import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Store } from "./Store";
import { GraphqlClient, ApiClient } from "~libs/api";
import { DiscoveryFeedPostType } from "~views/gatedPages/DiscoveryFeed/DiscoveryFeedUtils";
import Cookies from "js-cookie";

export type IRecommendation = "collections" | "resources";

export type ILanguageSlug = "en" | "es" | "sv" | "fr" | "nl";

export type IDiscoveryFeedUri = "en-discovery-feed" | "es-discovery-feed" | "sv-discovery-feed" | "fr-discovery-feed" | "nl-discovery-feed";

export interface IRecommendationInterface {
  items: any[];
  page: number;
  count: number;
  postTypes: string[];
  fetchIfNecessary: (amount: number) => Promise<DiscoveryFeedPostType[]>;
  requestStatus: "idle" | "pending" | "error";
  preferencesHasResult: boolean;
}

export class RecommendationStore {
  rootStore: Store;

  randomSeed: number = 0;

  page: number = 1;

  spotlightRequest: "idle" | "pending" | "error" = "idle";

  spotlightItems: DiscoveryFeedPostType[] = [];

  currentLanguageHasSpotlightItems: boolean = true;

  seed: number | null;

  recommendations: {
    [key in IRecommendation]: IRecommendationInterface;
  } = {
    collections: {
      items: [],
      page: 0,
      count: -1,
      postTypes: ["collection"],
      fetchIfNecessary: (amount: number) => this.fetchIfNecessary(amount, "collections"),
      requestStatus: "idle",
      preferencesHasResult: true
    },
    resources: {
      items: [],
      page: 0,
      count: -1,
      postTypes: ["document", "article"],
      fetchIfNecessary: (amount: number) => this.fetchIfNecessary(amount, "resources"),
      requestStatus: "idle",
      preferencesHasResult: true
    }
  };

  constructor(rootStore: Store, private graphqlClient: GraphqlClient, private authenticatedClient: ApiClient) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    this.onMount();

    reaction(
      () => [this.rootStore.preferenceStore.taxonomies.documentCountries.selectedItems, this.rootStore.preferenceStore.taxonomies.documentTopics.selectedItems],
      () => {
        this.resetRecommendations();
      }
    );

    reaction(
      () => this.rootStore.languageStore.currentLanguage,
      () => this.currentLanguageHasSpotlightItems
    );
  }

  onMount = () => {
    this.setRandomSeed();
  };

  /* Use timestamp 24 hour in the future if no seed is set or current seed is older than 24 hours */
  setRandomSeed = () => {
    const cookie = Cookies.get("discoverySeed");
    this.seed = cookie && Date.now() < cookie ? cookie : new Date(new Date().getTime() + 24 * 60 * 60 * 1000).getTime();
  };

  resetRecommendations = () => {
    const keys = Object.keys(this.recommendations) as IRecommendation[];

    keys.forEach(key => {
      this.recommendations[key].page = 0;
      this.recommendations[key].items = [];
      this.recommendations[key].count = -1;
      this.recommendations[key].preferencesHasResult = true;
    });
  };

  setPage = (type: IRecommendation) => {
    this.recommendations[type].count++;
  };

  shuffleSpotlightItems = () => {
    this.spotlightItems = shuffle(this.spotlightItems, this.seed);
  };

  shuffleRecommendation = (type: IRecommendation) => {
    this.recommendations[type].items = shuffle(this.recommendations[type].items, this.seed);
  };

  fetchIfNecessary = async (amount: number, type: IRecommendation) => {
    const recommendationInterface = this.recommendations[type];
    const { items, count } = recommendationInterface;

    const hasMoreItems = count === -1 || count > items.length;

    if (amount > items.length && hasMoreItems) {
      this.recommendations[type].page++;

      await this.fetchRecommendations(type);
    }

    return items.slice(0, amount);
  };

  fetchRecommendations = async (type: IRecommendation, refetch: boolean = false) => {
    if (!refetch && this.recommendations[type].requestStatus !== "idle") {
      return;
    }

    this.recommendations[type].requestStatus = "pending";

    const postBody = this.getRequestBody(type);

    const response = await this.authenticatedClient.getDocuments(JSON.stringify(postBody));

    /**
     * TODO: Handle case where given preferences results in empty result?
     */
    runInAction(() => {
      if (response.ok) {
        if (!!response.data.documents?.length) {
          if (this.recommendations[type].page > 1) {
            this.recommendations[type].items = this.recommendations[type].items.concat(shuffle(response.data.documents, this.seed));
          } else {
            this.recommendations[type].items = shuffle(response.data.documents, this.seed);
            this.recommendations[type].count = response.data.count;
          }
        } else if (!refetch) {
          this.recommendations[type].preferencesHasResult = false;
          this.fetchRecommendations(type, true);
        }
        this.recommendations[type].requestStatus = "idle";
        return this.recommendations[type].items;
      }
    });
  };

  getSpotlightItems = async () => {
    if (this.spotlightItems.length) {
      this.shuffleSpotlightItems();
    } else {
      this.fetchSpotlightItems();
    }
  };

  fetchSpotlightItems = async (refetch: boolean = false) => {
    if (!refetch && this.spotlightRequest !== "idle") {
      return;
    }

    this.spotlightRequest = "pending";

    const languageSlug = this.rootStore.languageStore?.currentLanguage?.slug as ILanguageSlug;

    if (!languageSlug) {
      this.spotlightRequest = "idle";
      return;
    }

    const nodeUri = this.currentLanguageHasSpotlightItems ? ((languageSlug + "-discovery-feed") as IDiscoveryFeedUri) : "en-discovery-feed";

    const response = await this.graphqlClient.getSpotlightItems(nodeUri);

    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();

        if (errors) {
          this.spotlightRequest = "error";
        } else if (!!data.nodeByUri?.discoveryFeedFields.spotlightItems) {
          this.spotlightItems = data.nodeByUri.discoveryFeedFields.spotlightItems;
        } else if (!refetch) {
          this.currentLanguageHasSpotlightItems = false;
          this.fetchSpotlightItems(true);
        }

        this.spotlightRequest = "idle";
      }
    });
  };

  /**
   * TODO: Refactor preferences when collection also supports them
   */
  getRequestBody = (type: IRecommendation) => {
    return {
      page: this.recommendations[type].page,
      language: this.rootStore.languageStore?.currentLanguage?.slug || this.rootStore.languageStore.getUrlLanguageSlug() || "en",
      peer_reviewed: "false",
      recommended: "false",
      sort_by: "Relevance",
      document_topics: this.recommendations[type].preferencesHasResult ? this.rootStore.preferenceStore.taxonomies.documentTopics.selectedItems.map(topic => topic?.slug) : [],
      document_countries: this.recommendations[type].preferencesHasResult ? this.rootStore.preferenceStore.taxonomies.documentCountries.selectedItems.map(country => country?.slug) : [],
      post_type: type === "resources" ? ["document", "internal_document"] : ["collection", "internal_collection"],
      page_size: 10,
      indices: ["public_search", "internal_search"],
      inclusive: ["document_countries", "document_topics"]
    };
  };
}

/* Randomize array using Fisher–Yates algorithm */
function shuffle<T>(array: T[], seed: number): T[] {
  const copy = [...array];

  let m: number = copy.length,
    t: T,
    i: number;

  while (m) {
    i = Math.floor(random(seed) * m--);
    t = copy[m];
    copy[m] = copy[i];
    copy[i] = t;
    ++seed;
  }

  return copy;
}

function random(seed: number): number {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}
