import { makeAutoObservable } from "mobx";

import { Store } from "./Store";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";

export class KeywordStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // General variables
  allKeywords: ISingleDocumentTaxonomy[] = [];

  keywordsToRender: {
    key: string;
    keywords: ISingleDocumentTaxonomy[];
  }[][] = [[]];

  // Filter variables
  allFilterLetters: string[] = [];

  selectedFilterLetters: string[] = [];

  // Pagination variables
  numberOfPages: number = 1;

  currentPage: number = 1;

  // Search variables
  searching: boolean = false;

  searchQuery: string = "";

  searchResults: ISingleDocumentTaxonomy[] = [];

  initKeywordStore = (keywords: ISingleDocumentTaxonomy[]) => {
    if (this.allKeywords.length === 0) {
      this.setAllKeywords(keywords);
      this.setFilterLetters();
      this.splitKeywordsToPages();
    }
  };

  splitKeywordsToPages = () => {
    const nrOfKeywordsPerPage = 180;
    const tmpKeywordsArray = [];
    let pageIndex = 0;

    let keywordsToTraverse;

    keywordsToTraverse = this.searching ? this.searchResults : this.allKeywords;

    keywordsToTraverse &&
      keywordsToTraverse.forEach((keyword, index) => {
        if (keyword?.language?.slug != this.rootStore?.languageStore?.currentLanguage?.slug) return;
        const firstLetter = this.getFirstLetter(keyword.name);

        if (!this.isActiveFilterLetter(firstLetter)) return;

        if (tmpKeywordsArray[pageIndex]) {
          const letterIndex = tmpKeywordsArray[pageIndex].findIndex(obj => obj.key === firstLetter);
          if (letterIndex >= 0) {
            tmpKeywordsArray[pageIndex][letterIndex].keywords.push(keyword);
          } else {
            tmpKeywordsArray[pageIndex].push({
              key: firstLetter,
              keywords: [keyword]
            });
          }
        } else {
          tmpKeywordsArray[pageIndex] = [{ key: firstLetter, keywords: [keyword] }];
        }
        if (index % nrOfKeywordsPerPage === nrOfKeywordsPerPage - 1) pageIndex += 1;
      });

    this.keywordsToRender = tmpKeywordsArray;
    this.numberOfPages = tmpKeywordsArray.length;
  };

  //  Collect functions
  setAllKeywords = (keywords: ISingleDocumentTaxonomy[]) => {
    this.allKeywords = keywords;
  };

  setFilterLetters = () => {
    let keywordsToTraverse = !this.searching ? this.allKeywords : this.searchResults;

    this.allFilterLetters = this.getKeywordsLetters(keywordsToTraverse).sort();
  };

  getKeywordsLetters = (keywords: ISingleDocumentTaxonomy[]) => {
    const tmpKeywords = [];
    keywords.forEach(keyword => {
      const firstLetter = this.getFirstLetter(keyword.name);
      if (!tmpKeywords.includes(firstLetter)) {
        tmpKeywords.push(firstLetter);
      }
    });
    return tmpKeywords;
  };

  getFirstLetter = (string: string) => {
    return string?.charAt(0)?.toLowerCase();
  };

  // Filter functions
  filterByLetter = (letter: string) => {
    if (this.selectedFilterLetters.includes(letter)) {
      const index = this.selectedFilterLetters.indexOf(letter);
      this.selectedFilterLetters.splice(index, 1);
    } else {
      this.selectedFilterLetters.push(letter);
    }
  };

  isActiveFilterLetter = letter => {
    if (this.selectedFilterLetters.length === 0) return true;

    if (this.isNumber(letter) && this.selectedFilterLetters.includes("0-9")) return true;

    if (this.selectedFilterLetters.includes(letter)) return true;
  };

  resetFilterLetters = () => {
    this.allFilterLetters = [];
    this.setFilterLetters();
  };

  // Pagination functions
  setPage = page => {
    this.currentPage = page;
  };

  // Misc functions
  isNumber = item => {
    return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(item);
  };

  // Search functions
  resetSearch = () => {
    this.searchQuery = "";
    this.searching = false;
    this.splitKeywordsToPages();
    this.resetFilterLetters();
  };

  setQuery = e => {
    const query = e.target.value.toLowerCase();
    if (query.length === 0) this.resetSearch();

    this.searchQuery = query;
    this.selectedFilterLetters = [];
    this.searchKeywords();
  };

  searchKeywords = () => {
    this.searching = true;
    const query = this.searchQuery;
    let results = [];

    this.allKeywords.forEach(keyword => {
      const { name } = keyword;
      let hasMatch = false;
      hasMatch = name.toLowerCase().includes(query);
      if (hasMatch && !results.some(result => result.name === name)) {
        results.push(keyword);
      }
    });

    if (results.length === 0) {
      this.keywordsToRender = [];
    } else {
      this.searchResults = results;
      this.splitKeywordsToPages();
      this.resetFilterLetters();
    }
  };
}
