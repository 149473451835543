import { IDiscoveryFeedUri } from "~stores/RecommendationStore";
import { GUser, GToolkit, GInternalMediaItem, GInternalPDF } from "./graphqlQueryPartials";
import { GArticleListView } from "./graphqlQueryPartials/article";
import { GCollectionListView } from "./graphqlQueryPartials/collection";

import { GDocument, GDocumentListView } from "./graphqlQueryPartials/document";
import { GInternalCollection, GInternalCollectionListView } from "./graphqlQueryPartials/internalCollection";
import { GInternalDocumentListView, GInternalDocument } from "./graphqlQueryPartials/internalDocument";

export class GraphqlClient {
  private token: string = null;

  setToken = (token: string) => {
    this.token = token;
  };

  isAuthorized = () => !!this.token;

  getHeader = (forceNoAuth = false, tokenOverride?: string) => {
    const header = {
      "Content-Type": "application/json"
    };
    if (!forceNoAuth && (this.token || tokenOverride)) {
      header["Authorization"] = `Bearer ${this.token || tokenOverride}`;
    }
    return header;
  };

  performRequest = (query: string, forceNoAuth = false, tokenOverride?: string) =>
    fetch(`${process.env.GATSBY_WP_BASE_GRAPHQL}`, {
      headers: this.getHeader(forceNoAuth, tokenOverride),
      method: "POST",
      body: JSON.stringify({
        query
      })
    });

  refreshToken = (token: string) =>
    this.performRequest(
      `
      mutation RefreshAuthToken {
        refreshJwtAuthToken(input: {clientMutationId: "${Math.random()}", jwtRefreshToken: "${token}"}) {
          authToken
        }
      }`,
      true
    );

  login = (username: string, password: string) =>
    this.performRequest(`
      mutation LoginUser {
        login(input: {clientMutationId: "${Math.random()}", username: "${username}", password: "${password}"}) {
          authToken
          user {
            ${GUser}
            jwtAuthToken
            jwtAuthExpiration
            jwtRefreshToken
            roles {
              nodes {
                displayName
                name
              }
            }
          }
        }
      }`);

  getCurrentUser = () =>
    this.performRequest(`
      query GetCurrentUser {
        viewer {
          ${GUser}
          roles {
            nodes {
              displayName
              name
            }
          }
        }
      }
    `);

  resetPassword = (username: string) =>
    this.performRequest(`
      mutation sendPasswordResetEmail {
        sendPasswordResetEmail(
          input: {
            clientMutationId: "${Math.random()}", 
            username: "${username}"
          }) {
          user {
            name
            id
          }
        }
      }
    `);

  updateName = (id: string, newName: string) =>
    this.performRequest(`
      mutation UpdateName {
        updateUser( input: {
            id: "${id}",
            displayName: "${newName}",
          }) {
            user {
              name
            }
        }
      }`);

  addPostToUserLibrary = (id: number) =>
    this.performRequest(`
      mutation addPostToUserLibrary {
        addPostToUserLibrary(
          input: { postId: ${id} }
        ) {
          success
          message
        }
    }`);

  removePostFromUserLibrary = (id: number) =>
    this.performRequest(`
      mutation removePostFromUserLibrary {
        removePostFromUserLibrary(
          input: { postId: ${id} }
        ) {
          success
          message
        }
    }`);

  getUserLibraryModified = () =>
    this.performRequest(`
      query getUserLibraryModified {
        viewer {
          userLibrary {
            modified
          }
        }
      }
    `);

  getUserLibrary = () =>
    this.performRequest(`
      query getUserLibrary {
        viewer {
          userLibrary {
            userLibraryPosts {
              __typename
              ... on InternalDocument {
                ${GInternalDocumentListView}
              }
              ... on Toolkit {
                ${GToolkit}
              }
              ... on Article {
                ${GArticleListView}
              }
              ... on Document {
                ${GDocumentListView}
              }
            }
          }
        }
      }`);

  updatePassword = (id: string, password: string) =>
    this.performRequest(`
      mutation UpdatePassword {
        updateUser( input: {
            id: "${id}",
            password: "${password}",
          }) {
            user {
              name
            }
        }
      }`);

  getUserPreferences = () =>
    this.performRequest(`
      query getUserPreferences {
        viewer {
          userPreferences {
            documentCountries {
              id
              databaseId
              name
              __typename
              slug
            }
            documentTopics {
              id
              databaseId
              name
              __typename
              slug
            }
          }
        }
      }
    `);

  getCurrentUserInternalCollections = () =>
    this.performRequest(`
      query getViewer {
        viewer {
          internalCollections {
            nodes {
              ${GInternalCollectionListView}
            }
          }
        }
      }`);

  getInternalDocumentModified = (id: string, token?: string) =>
    this.performRequest(
      `
      query getInternalDocumentModified {
        internalDocument(id: "${id}") {
          modified
        }
      }
    `,
      false,
      token
    );

  getInternalDocument = (id: string, idType: string = "ID", token?: string) =>
    this.performRequest(
      `
      query getInternalDocument {
        internalDocument(id: "${id}", idType: ${idType}) {
          ${GInternalDocument}
        }
      }`,
      false,
      token
    );

  getInternalPDF = (id: string, idType: string = "ID", token?: string) =>
    this.performRequest(
      `
      query getInternalPDF {
        mediaItem(id: "${id}", idType: ${idType}) {
          ${GInternalPDF}
        }
      }`,
      false,
      token
    );

  getInternalMediaItem = (id: string, token?: string) =>
    this.performRequest(
      `
      query getInternalMediaItem {
        mediaItem(id: "${id}") {
          ${GInternalMediaItem}
        }
      }`,
      false,
      token
    );

  getInternalCollectionModified = (id: string, token?: string) =>
    this.performRequest(
      `
      query getInternalCollection {
        internalCollection(id: "${id}") {
          userCanEdit
          modified
        }
      }
      `,
      false,
      token
    );

  getInternalMediaItemModified = (id: string, token?: string) =>
    this.performRequest(
      `
      query getInternalMediaItem {
        internalCollection(id: "${id}") {
          userCanEdit
          modified
        }
      }
      `,
      false,
      token
    );

  getInternalCollection = (id: string, token?: string) =>
    this.performRequest(
      `
      query getInternalCollection {
        internalCollection(id: "${id}") {
          ${GInternalCollection}
        }
      }
      `,
      false,
      token
    );

  getSpotlightItems = (uri: IDiscoveryFeedUri, token?: string) =>
    this.performRequest(
      `
    query getSpotlightItems {
      nodeByUri(uri: "${uri}") {
        ... on Page {
          discoveryFeedFields {
            spotlightItems {
              ... on Document {
                ${GDocumentListView}
              }
              ... on InternalDocument {
                ${GInternalDocumentListView}
              }
              ... on InternalCollection {
                ${GInternalCollectionListView}
              }
            }
          }
        }
      }
    }
    `,
      false,
      token
    );

  getDocument = (id: string, idType: string = "ID") =>
    this.performRequest(
      `
      query getDocument {
        document(id: "${id}", idType: ${idType}) {
          ${GDocument}
        }
      }`,
      false
    );
}
