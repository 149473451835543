import React from "react";
import * as s from "./UserLoginModal.module.scss";

import { tx } from "~libs/i18n";
import { Button } from "~views/shared/components/Button";
import { ButtonSpinner } from "~views/shared/components/ButtonSpinner";
import { Input } from "~views/shared/components/Input";
import { Modal } from "~views/shared/components/Modal";
import { P } from "~views/shared/components/Paragraph";
import { PseudoLink } from "~views/shared/components/PseudoLink";
import { EmailSmall, LockSmall } from "~libs/assets/icons";
import { MobXProviderContext, observer } from "mobx-react";

export const USER_LOGIN_EMAIL_FIELD_ID = "user-login-email";

export const UserLoginModal = observer(() => {
  const store = React.useContext(MobXProviderContext);
  const { loginStore, registerStore, uiStore } = store;
  const { login, closeLoginModal, userEmail, setUserEmail, userPassword, setUserPassword, sendPasswordResetEmail, requestStatusMessage, loginRequestStatusType, loginModalOpen, SSOLogin, checkSCEmailDomain, isSCEmail, formIsValid } = loginStore;
  const { openRegisterModal } = registerStore;
  const isDesktop = uiStore?.isEqualOrGreatherThanBreakpoint("lg");

  let requestStatusMessageContainerClasses = [s.requestStatusMessageContainer];
  let requestStatusMessageClasses = [s.requestStatusMessage];
  if (requestStatusMessage !== "") {
    requestStatusMessageContainerClasses.push(s.visible);
    if (loginRequestStatusType === "error") requestStatusMessageClasses.push(s.error);
  }

  return (
    <Modal title={tx("authentication.login")} open={loginModalOpen} onClose={closeLoginModal}>
      <div className={s.titleWrapper}>
        <h3 className={s.title}>
          <span className={s.red}>{tx("authentication.signIn")}</span>
          <span>{tx("authentication.toYourAccount")}</span>
        </h3>
      </div>

      <div className={s.registerContainer}>
        <P medium className={s.registerText}>
          {tx("authentication.dontHaveAnAccount")}
        </P>

        <PseudoLink
          className={s.link}
          onClick={() => {
            closeLoginModal();
            openRegisterModal();
          }}
        >
          <P medium>{tx("authentication.registerNow")}</P>
        </PseudoLink>
      </div>
      <div className={s.contentContainer}>
        <div className={s.emailRow}>
          <EmailSmall className={s.inputIcon} />
          <Input type="email" label={`${tx("input.email")}:`} htmlFor={USER_LOGIN_EMAIL_FIELD_ID} placeholder={tx("misc.startTyping")} value={userEmail} onChange={setUserEmail} onBlur={checkSCEmailDomain} />
        </div>
        {isSCEmail && <p className={s.staffLoginText}>{tx("misc.useStaffLogin")}</p>}
        <div className={s.inputContainer}>
          <LockSmall className={s.inputIcon} />
          <Input type="password" label={`${tx("input.password")}:`} htmlFor="user-login-password" placeholder={tx("misc.startTyping")} showIcon value={userPassword} onChange={setUserPassword} onEnter={login} />
        </div>
        <div className={requestStatusMessageContainerClasses.join(" ")}>
          <P small className={requestStatusMessageClasses.join(" ")}>
            {!(requestStatusMessage === "idle" || requestStatusMessage === "pending") && requestStatusMessage}
          </P>
        </div>
        <div className={s.buttonContainer}>
          <Button size={isDesktop ? "large" : "medium"} color="red" className={s.button} disabled={!formIsValid || loginRequestStatusType === "pending"} onClick={login}>
            {loginRequestStatusType === "pending" ? <ButtonSpinner /> : tx("authentication.signIn")}
          </Button>
        </div>
        <PseudoLink className={s.forgotPasswordLink} onClick={sendPasswordResetEmail}>
          <P small className={s.forgotPasswordText}>
            {tx("authentication.forgotPassword")}
          </P>
        </PseudoLink>
        <div className={s.buttonContainer}>
          <Button size={isDesktop ? "large" : "medium"} color="red" className={s.button} onClick={SSOLogin}>
            {tx("authentication.staffLogin")}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
