import { makeAutoObservable } from "mobx";
import { Store } from "./Store";
import { disableBodyScrollAndTranslate, resetBodyScroll } from "~libs/utils";

export class SectionsContainerStore {
  rootStore: Store;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  offsetEnabled: boolean = false;

  sectionsContainerYOffset: number = 0;

  hasOpenOverlay: boolean = false;

  setScrollPos = () => {
    const tempCurrentYPos = typeof window !== "undefined" && window.pageYOffset;
    disableBodyScrollAndTranslate(0, tempCurrentYPos, 0);
    this.offsetEnabled = true;
    this.hasOpenOverlay = true;
    this.sectionsContainerYOffset = tempCurrentYPos;
  };

  resetScrollPos = (yOffset?) => {
    this.offsetEnabled = false;
    this.hasOpenOverlay = false;
    resetBodyScroll();
    if (typeof window !== "undefined" && window) {
      window.scrollTo(0, yOffset ? yOffset : this.sectionsContainerYOffset);
    }
    this.sectionsContainerYOffset = 0;
  };
}
