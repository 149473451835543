import { makeAutoObservable, reaction } from "mobx";

import { Store } from "./Store";
import { tx } from "~libs/i18n";
import { clearHash } from "~libs/utils";
import { ApiClient } from "~libs/api";

export class RegisterStore {
  rootStore: Store;

  constructor(rootStore: Store, private unauthenticatedClient: ApiClient) {
    this.rootStore = rootStore;
    this.resetSecurityVariables();
    makeAutoObservable(this);
    reaction(
      () => this.registerModalOpen,
      open => {
        this.resetSecurityVariables();
        if (open) {
          this.rootStore.sectionsContainerStore.setScrollPos();
        } else {
          this.rootStore.sectionsContainerStore.resetScrollPos();
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  username: string = "";

  usernameInvalid: boolean = false;

  usernameErrorMessage: string = "";

  email: string = "";

  emailInvalid: boolean = false;

  emailErrorMessage: string = "";

  password: string = "";

  passwordInvalid: boolean = false;

  passwordErrorMessage: string = "";

  passwordRepeat: string = "";

  passwordRepeatInvalid: boolean = false;

  passwordRepeatErrorMessage: string = "";

  passwordsMatch: boolean = false;

  registerModalOpen: boolean = false;

  reCaptchaValid: boolean = false;

  tacAccepted: boolean = false;

  registerUserMessage: string = "";

  registerUserMessageType: "error" | "success";

  isSCEmail: boolean = false;

  /**
   * Close the user register modal and reset security variables.
   */
  closeRegisterModal = () => {
    this.registerModalOpen = false;
    clearHash();
  };

  /**
   * Open the user register modal and reset security variables.
   */
  openRegisterModal = () => {
    this.registerModalOpen = true;
  };

  /**
   * Reset reCaptcha and TAC variables to default values.
   */
  resetSecurityVariables = () => {
    this.reCaptchaValid = false;
    this.tacAccepted = false;
  };

  /**
   * Set the username
   * @param  {string} username [The username string you want the username to be set to.]
   */
  setUsername = (username: string) => {
    if (username?.length === 0) {
      this.validateUsername();
    }
    this.username = username;
  };

  /**
   * Validate the username and set the username error message variable if the username is invalid.
   */
  validateUsername = () => {
    this.usernameInvalid = !this.rootStore.validationStore.validateUsername(this.username);

    if (this.usernameInvalid) {
      this.usernameErrorMessage = this.rootStore.validationStore.getUsernameErrorMessage(this.username);
    }
  };

  /**
   * Set the user email.
   * @param  {string} email [The email string you want the email to be set to.]
   */
  setEmail = (email: string) => {
    if (email?.length === 0) {
      this.validateEmail();
    }
    this.email = email;
  };

  /**
   * Validate the user email and set the user email error message variable if the email is invalid.
   */
  validateEmail = () => {
    this.emailInvalid = !this.rootStore.validationStore.validateEmail(this.email);

    if (this.emailInvalid) {
      this.emailErrorMessage = this.rootStore.validationStore.getEmailErrorMessage();
    }
  };

  /**
   * Set the user password.
   * @param  {string} password [The password string you want the password to be set to.]
   */
  setPassword = (password: string) => {
    if (password?.length === 0) {
      this.validatePassword();
    }
    this.password = password;
  };

  initPasswords = () => {
    this.password = "";
    this.passwordRepeat = "";

    this.passwordInvalid = false;
    this.passwordRepeatInvalid = false;
  };
  /**
   * Set the user repeated password.
   * @param  {string} password [The password string you want the repeat password to be set to.]
   */
  setPasswordRepeat = (password: string) => {
    if (password?.length === 0) {
      this.validatePassword();
    }
    this.passwordRepeat = password;
  };

  /**
   * Validate the user password and set the user password error message
   * variable if the password is invalid.
   */
  validatePassword = () => {
    this.passwordInvalid = !this.rootStore.validationStore.validatePassword(this.password);

    if (this.passwordInvalid) {
      this.passwordErrorMessage = this.rootStore.validationStore.getPasswordErrorMessage(this.password);
    }
  };

  /**
   * Validate the user repeat password and set the user repeat password error message
   * variable if the repeat password is invalid.
   */
  validatePasswordRepeat = () => {
    this.passwordRepeatInvalid = !this.rootStore.validationStore.validatePassword(this.passwordRepeat);

    if (this.passwordRepeatInvalid) {
      this.passwordRepeatErrorMessage = this.rootStore.validationStore.getPasswordErrorMessage(this.passwordRepeat);
    } else {
      this.checkPasswordsMatch();
    }
  };

  /**
   * Check if the user passwords match and update the userPasswordsMatch variable.
   */
  checkPasswordsMatch = () => {
    this.passwordsMatch = this.password === this.passwordRepeat;
    if (!this.passwordsMatch) {
      this.passwordRepeatInvalid = true;
      this.passwordRepeatErrorMessage = this.rootStore.validationStore.getPasswordErrorMessage(this.password, this.passwordRepeat);
    }
  };

  /**
   * Toggle the reCaptcha variable.
   */
  toggleReCaptcha = () => {
    this.reCaptchaValid = !this.reCaptchaValid;
  };

  /**
   * Toggle the TAC variable.
   */
  toggleTac = () => (this.tacAccepted = !this.tacAccepted);

  /**
   * Register a user in the wordpress backend.
   * Setting appropriate request status message depending on the request result, either "error" or "success".
   * If "error" then show error message for a short period of time.
   * If "success" then show success message for a short period of time then close the register modal.
   */
  registerUser = async () => {
    const { username, password, email } = this;
    const response = await fetch(`${process.env.GATSBY_WP_BASE_GRAPHQL}`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        query: `
          mutation RegisterUser {
            registerUser(
              input: {
                clientMutationId: "${Math.random()}",
                username: "${username}",
                password: "${password}"
                email: "${email}"
              }) {
              user {
                jwtAuthToken
                jwtRefreshToken
              }
            }
          }
        `
      })
    });

    if (response.ok) {
      const { data, errors } = await response.json();
      if (errors) {
        const { message } = errors?.[0];
        this.registerUserMessageType = "error";
        this.registerUserMessage = message.substr(message.indexOf(":") + 1);
        setTimeout(() => {
          this.registerUserMessage = "";
        }, 5000);
      } else {
        this.registerUserMessageType = "success";
        this.registerUserMessage = tx("authentication.registrationComplete");
        setTimeout(() => {
          this.registerUserMessage = "";
          this.rootStore.registerStore.closeRegisterModal();
          this.rootStore.loginStore.openLoginModal();
        }, 5000);
      }
    } else {
      console.error(response);
    }
  };

  handleHashChange = () => {
    if (window.location.hash === "#register") {
      !this.registerModalOpen && this.openRegisterModal();
    } else {
      this.registerModalOpen && this.closeRegisterModal();
    }
  };

  onMount = () => {
    this.handleHashChange();
    window.addEventListener("hashchange", this.handleHashChange, false);
  };

  onUnmount = () => {
    window.removeEventListener("hashchange", this.handleHashChange, false);
  };

  checkSCEmailDomain = () => {
    this.unauthenticatedClient
      .getIsEmailSCDomain(this.email)
      .then(response => {
        this.isSCEmail = !!response.data;
      })
      .catch(_ => {});
  };
}
