import { makeAutoObservable } from "mobx";
import { Store } from "./Store";
import { tx } from "~libs/i18n";

export class AdvancedSearchStore {
  constructor(private rootStore: Store) {
    makeAutoObservable(this);
  }

  advancedSearchOverlayOpen: boolean = false;

  advancedSearchStringTypes: {
    value: string;
    slug: string;
    selected: boolean;
  }[] = [
    { value: tx("search.title"), slug: "title", selected: false },
    { value: tx("search.abstract"), slug: "abstract", selected: false },
    { value: tx("search.text"), slug: "document_content", selected: false }
  ];

  allStringsToInclude: { value: string }[] = [];

  minimumStringsToInclude: { value: string }[] = [];

  exactStringToInclude: { value: string }[] = [];

  allStringsToExclude: { value: string }[] = [];

  publicationYearsToInclude: { value: number }[] = [];

  advancedSearchActive: boolean = false;

  postBody: any;

  toggleAdvancedSearchActive = () => {
    this.advancedSearchActive = !this.advancedSearchActive;
  };

  setAdvancedSearchActive = active => {
    this.advancedSearchActive = active;
  };

  clearAll = () => {
    this.unselectSearchStringTypes();
    [this.allStringsToInclude, this.minimumStringsToInclude, this.exactStringToInclude, this.allStringsToExclude, this.publicationYearsToInclude].forEach(array => this.clearArray(array));
  };

  unselectSearchStringTypes = () => {
    this.advancedSearchStringTypes = this.advancedSearchStringTypes.map(type => {
      return {
        ...type,
        selected: false
      };
    });
  };

  clearArray = array => {
    array.forEach(childArray => {
      if (Array.isArray(childArray)) {
        childArray.splice(0, childArray.length);
      }
    });
    array.splice(0, array.length);
  };

  toggleStringFilterChip = (allOptions, optionObject) => {
    if (allOptions.some(item => item.value === optionObject.value)) {
      const index = allOptions.indexOf(optionObject);
      if (index > -1) {
        allOptions.splice(index, 1);
      }
    } else {
      allOptions.push(optionObject);
    }
  };

  toggleDropdownOption = (allOptions, optionObject) => {
    const optionIndex = allOptions.findIndex(item => item.id === optionObject.value);
    optionObject.selected = !optionObject.selected;
    allOptions[optionIndex] = optionObject;
  };

  toggleYear = (rangeStart: number = null, rangeEnd: number = null) => {
    if (rangeStart === null && rangeEnd === null) {
      this.publicationYearsToInclude = [];
    } else if (rangeStart !== null && rangeEnd === null) {
      this.publicationYearsToInclude = [{ value: rangeStart }];
    } else if (rangeStart !== null && rangeEnd !== null) {
      this.publicationYearsToInclude = [];
      for (let i = rangeStart; i <= rangeEnd; i++) {
        this.publicationYearsToInclude.push({ value: i });
      }
    }
  };

  toggleAdvancedSearchOverlay = () => {
    this.advancedSearchOverlayOpen = !this.advancedSearchOverlayOpen;
    if (this.advancedSearchOverlayOpen) {
      this.rootStore.sectionsContainerStore.setScrollPos();
    } else {
      this.rootStore.sectionsContainerStore.resetScrollPos();
    }
  };

  generateQueryParams = () => {
    this.postBody = {
      advanced: {}
    };

    const { keywords, prioritize } = this.rootStore.filterStore;

    if (this.advancedSearchStringTypes) {
      this.postBody["advanced"]["fields"] = this.advancedSearchStringTypes.filter(type => type.selected).map(type => type.slug);
    }

    if (this.allStringsToInclude) {
      this.postBody["advanced"]["must"] = this.allStringsToInclude.map(string => string.value);
    }

    if (this.minimumStringsToInclude) {
      this.postBody["advanced"]["should"] = this.minimumStringsToInclude.map(string => string.value);
    }

    if (this.allStringsToExclude) {
      this.postBody["advanced"]["must_not"] = this.allStringsToExclude.map(string => string.value);
    }

    if (this.exactStringToInclude) {
      this.postBody["advanced"]["exact_phrase"] = this.exactStringToInclude.map(phrase => phrase.value);
    }

    if (keywords) {
      this.postBody["document_keywords"] = [];
      keywords
        .filter(keyword => keyword.selected)
        .forEach(keyword => {
          this.postBody["document_keywords"].push(keyword.value);
        });
    }

    if (this.publicationYearsToInclude && this.publicationYearsToInclude.length) {
      this.postBody["publication_year_start"] = [];
      this.postBody["publication_year_start"].push(this.publicationYearsToInclude[0].value.toString());
      if (this.publicationYearsToInclude.length > 1) {
        this.postBody["publication_year_end"] = [];
        this.postBody["publication_year_end"].push(this.publicationYearsToInclude[this.publicationYearsToInclude.length - 1].value.toString());
      }
    }

    if (prioritize && prioritize.filter(p => p.selected)) {
      prioritize.forEach(prio => {
        this.postBody[prio.slug] = prio.selected.toString();
      });
    }
  };

  get getAdvancedSearchParams() {
    this.generateQueryParams();
    return this.postBody;
  }

  performAdvancedSearch = () => {
    const { getDocuments } = this.rootStore.searchStore;
    getDocuments(this.getAdvancedSearchParams);
  };
}
