import { makeAutoObservable, toJS } from "mobx";

import { Store } from "./Store";
import { ApiClient } from "~libs/api";
import { ISubscriptionTypes, ISubscriptionTaxonomy, ISubscriptionBody, defaultSubscriptionTaxonomies } from "~models/Subscription";

export interface ITerm {
  term_id: number;
  taxonomy: string;
  name: string;
}

export interface ISubscription {
  id: number;
  email: string;
  frequency: string;
  terms?: { [key in ISubscriptionTypes]: ISubscriptionTaxonomy };
}

const convertFormDataToSubscription = (data: ISubscriptionBody): ISubscription => {
  const copy = JSON.parse(JSON.stringify(toJS(defaultSubscriptionTaxonomies))); // We have to copy this in order to not populate the model.
  const terms = data.terms
    ?.map(({ nodeType, name, id }) => ({ nodeType, id, value: name, selected: true }))
    ?.reduce((acc, term) => {
      Object.values(acc)
        ?.find(parent => parent.nodeType === term.nodeType)
        ?.items?.push(term);
      return acc;
    }, copy);

  return {
    id: data.id,
    email: data.email,
    frequency: data.frequency,
    terms
  };
};

export class SubscriptionStore {
  constructor(private rootStore: Store, private client: ApiClient) {
    makeAutoObservable(this);
  }

  requestStatus: "idle" | "pending" = "idle";

  subscriptions: ISubscription[] = [];

  getNewsletterSubscriptions = async () => {
    this.requestStatus = "pending";
    const result = await this.client.getNewsletterSubscriptions();
    if (result.ok) {
      this.subscriptions = result.data.map(convertFormDataToSubscription);
    }
    this.requestStatus = "idle";
  };

  destroyNewsletterSubscription = async id => {
    const result = await this.client.destroyNewsletterSubscriptions(id);
    if (result.status == 200) {
      this.subscriptions = this.subscriptions.filter(s => s.id != id);
    }
  };
}
